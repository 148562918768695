<template>
  <div
    class="doc-box"
    :style="{ height: ['validator'].includes(type) ? 'calc(100vh - 51px)' : '100vh' }"
    :class="{ noselect: drawing }"
    @wheel="handleWheel"
  >
    <div
      ref="pageContainer"
      class="page-container"
      :style="pageContainerStyle"
    >
      <CanvasSide
        v-if="selectedTab !== 'text' && textSelect && drawing"
        :page-width="pageWidth"
        :page-height="pageHeight"
        :display-page-width="displayPageWidth"
        :display-page-height="displayPageHeight"
        :page-container-height="pageContainerHeight"
        :page-container-width="pageContainerWidth"
        :container-side-width="containerSideWidth"
        :container-top-height="containerTopHeight"
        :container-width="containerWidth"
        :rotation="rotation"
        :updated-coordinates="updatedCoordinates"
        :max-x="maxX"
        :max-y="maxY"
        @draw-end="drawEnd"
        @update-y="updateY"
        @update-x="updateX"
      />
      <div
        v-if="noDocsFound"
        class="vertical-centered"
      >
        {{ $t("docViewer.no_docs") }}
      </div>
      <DocText
        v-if=" selectedTab === 'text' && currentPageData"
        class="disable-horizontal-scroll"
        :text="tokens.map(w => w.word).join(' ')"
      />
      <div
        v-if="pageLoaded && selectedTab !== 'text'"
        ref="currentPage"
        class="current-page background-spread fade-in"
        :class="{ selectable: textSelect }"
        :style="{
          width: `${pageWidth}vh`,
          height: `${pageHeight}vh`,
          'background-image': `url(${pageImage})`,
          transform: `rotate(${rotation}deg) ${horizontalMiddleTranslate} ${verticalMiddleTranslate}`,
          top: pageTop,
          left: pageLeft,
          'box-shadow': `${pageShadow} 10px rgba(0, 0, 0, 0.2)`,
        }"
        @click.ctrl="handleClick"
      >
        <SearchHighlights
          :words="filteredSearchResults"
          :page-width="pageWidth"
          :page-height="pageHeight"
        />
        <ValueHighlights
          v-if="['validator', 'correction'].includes(type)"
          ref="valueHighlights"
          :highlighted="activeDatapoint"
          :extractor-values="pageValues"
          :verified="verified"
          :zoom="zoom"
          :page-width="pageWidth"
          :page-height="pageHeight"
          :original-width="currentPageData.dimension.width"
          :original-height="currentPageData.dimension.height"
          @highlight="highlight"
          @de-highlight="$emit('deHighlight')"
          @verify="verify"
        />
        <SingleValueHighlights
          v-if="type === 'configureDP' && selectedTab === 'values'"
          ref="singleValueHighlights"
          :highlighted="activeDatapoint"
          :data-point="pageValue"
          :verified="verified"
          :zoom="zoom"
          :page-width="pageWidth"
          :page-height="pageHeight"
          :original-width="currentPageData.dimension.width"
          :original-height="currentPageData.dimension.height"
          @highlight="highlight"
          @de-highlight="$emit('deHighlight')"
        />
        <ReviewValues
          v-if="type === 'review'"
          :values="reviewValues.filter(dp => dp.reviewPageNb === currentPage)"
          :page-width="pageWidth"
          :page-height="pageHeight"
          @highlight="dp => $emit('highlight', dp)"
          @verify="dp => $emit('verify', dp)"
        />
        <ReviewValueHighlight
          v-if="type === 'review'"
          :value="highlightedValue"
          :page-width="pageWidth"
          :page-height="pageHeight"
        />
        <GroupValueHighlights
          v-if="type === 'configureGroup' && selectedTab === 'values'"
          ref="groupValueHighlights"
          :value-groups="pageValues"
          :zoom="zoom"
          :page-width="pageWidth"
          :page-height="pageHeight"
          :original-width="currentPageData.dimension.width"
          :original-height="currentPageData.dimension.height"
          :highlighted="activeDatapoint"
          @highlight="highlight"
          @de-highlight="$emit('deHighlight')"
        />
        <div
          v-if="selectedTab !== 'text' && textSelect && area > 0"
          class="coordinate-box"
          :style="{
            left: `${getHighlightX(updatedCoordinates.x_min)}px`,
            top: `${getHighlightY(updatedCoordinates.y_min)}px`,
            width: `${getHighlightX(
              updatedCoordinates.x_max - updatedCoordinates.x_min
            )}px`,
            height: `${getHighlightY(
              updatedCoordinates.y_max - updatedCoordinates.y_min
            )}px`,
          }"
        />
        <EntitiesHighlights
          v-if="showEntities && !loading"
          :entities="customEntities"
          :entity-coordinates="entityCoordinates"
          :page-width="pageWidth"
          :page-height="pageHeight"
        />
        <TokenHighlights
          v-if="showTokens && !loading"
          :page-width="pageWidth"
          :page-height="pageHeight"
          :tokens="tokens"
          :annotation-coordinates="annotationCoordinates"
        />
        <ObjectHighlights
          :page-width="pageWidth"
          :page-height="pageHeight"
          :highlighted-object="highlightedObject"
          :object-values="objectValues"
          :current-page="currentPage ? currentPage : -1"
          @handle-object-enter="$emit('handleObjectEnter', $event)"
          @handle-object-leave="$emit('handleObjectLeave')"
        />
        <ReadingOrder
          v-if="showReadingOrder && !loading"
          :page-width="pageWidth"
          :page-height="pageHeight"
          :tokens="tokens"
          :annotation-coordinates="annotationCoordinates"
          @draw-svg="drawSVG"
          @erase-svg="eraseSVG"
        />
        <BlockHighlights
          v-if="showBlocks && !loading"
          :page-width="pageWidth"
          :page-height="pageHeight"
          :blocks="blocks"
        />
        <TableHighlights
          v-if="showTables && !loading"
          :page-width="pageWidth"
          :page-height="pageHeight"
          :tables="tables"
        />
        <AnnotationHighlights
          v-if="document && document.annotations && labelType === 'annotation'"
          ref="annotationHighlights"
          :annotations="document.annotations"
          :only-show-labels="onlyShowLabels"
          :current-page="currentPage ? currentPage : -1"
          :page-width="pageWidth"
          :page-height="pageHeight"
          :labels="labels"
          :editing="textSelect"
          @delete-annotation="
            (annotation) => $emit('deleteAnnotation', annotation)
          "
          @select-label="(label) => $emit('selectLabel', label, 'labels')"
          @change-label="(change) => $emit('changeLabel', [...change, 'annotations'])"
        />
        <AnnotationHighlights
          v-if="document && document.locationAnnotations && labelType === 'location'"
          ref="annotationHighlights"
          :annotations="document.locationAnnotations"
          :only-show-labels="onlyShowLabels"
          :current-page="currentPage ? currentPage : -1"
          :page-width="pageWidth"
          :page-height="pageHeight"
          :labels="labels"
          :editing="textSelect"
          @delete-annotation="
            (annotation) => $emit('deleteAnnotation', [...annotation, 'locationAnnotations'])
          "
          @select-label="(label) => $emit('selectLabel', label, 'locationLabels')"
          @change-label="(change) => $emit('changeLabel', [...change, 'locationAnnotations'])"
        />
        <div
          v-if="selectedTab !== 'text' && textSelect"
          class="canvas stretch"
          @mouseup="drawEnd"
          @mousedown="drawStart"
          @mousemove="drawMove"
        />
      </div>
      <div
        v-if="!pageLoaded && loadingDisplayDelayPassed && !noDocsFound"
        class="loading-page"
      >
        <v-icon
          class="vertical-centered"
          style="margin-top: -20px"
          color="white"
          size="30"
        >
          fas fa-spinner fa-pulse
        </v-icon>
      </div>
    </div>
    <div
      class="top-bar"
      :style="{
        height: `${showReadingOrder && '85px' || '40px'} !important`,
      }"
    >
      <div class="show-tokens-box">
        <div class="d-flex align-center flex-shrink-0">
          <v-checkbox
            v-model="showTokens"
            density="compact"
            :style="{ display: displayShowTokens }"
            :label="$t('models.show_words')"
            hide-details
          />
          <v-checkbox
            v-model="showEntities"
            class="mt-0 ml-4"
            density="compact"
            :style="{ display: displayShowEntities }"
            :label="$t('models.show_entities')"
            hide-details
          />
        </div>
        <div
          v-if="config.datascience || isDatascientist"
          class="datascience-bar"
        >
          <v-checkbox
            v-model="showBlocks"
            label="Show Blocks"
            hide-details
          />
          <v-checkbox
            v-model="showTables"
            class="mt-0"
            label="Show Tables"
            hide-details
          />
          <v-checkbox
            v-model="showReadingOrder"
            class="mt-0 ml-4"
            :label="$t('models.reading_order')"
            hide-details
          />
          <CustomSelect
            v-if="showReadingOrder"
            class="overflow-y-visible my-0 ml-1"
            name-field="title"
            value-field="value"
            width="250px"
            placeholder="select"
            :selected="selectedReadingOrder"
            :items="[
              { title: 'Default', value: 'default' },
              { title: 'Roma2', value: 'roma2' },
              { title: 'Roma3', value: 'roma3' },
            ]"
            @selected-changed="(value) => selectedReadingOrder = value"
            clickable
          />
          <v-checkbox
            v-if="showReadingOrder"
            v-model="useTables"
            class="mt-0 ml-4"
            color="primary"
            label="Use Tables"
            density="compact"
            hide-details
          />
        </div>
        <AnnotationToolbox
          v-if="type === 'annotation'"
          class="position-absolute"
          style="right: 10px; top: 28px;"
          :label-type="labelType"
        />
      </div>
      <ExtractorConfigToolbox
        v-if="tabs"
        v-model="selectedTab"
        v-model:selected-reading-order="selectedReadingOrder"
        v-model:use-tables="useTables"
        :available-info="availableInfo"
      />
    </div>
    <DocSearch
      v-if="searchable"
      ref="docSearch"
      :doc-id="document.id"
      :resource="searchResource"
      :loading-thumbs="loadingThumbs"
      :search-on="searchOn"
      @loading-changed="l => loadingThumbs = l"
      @change-page="word => currentPage = word.page_nb"
      @update-pages="computePages++"
      @close="searchOn = false"
    />
    <div class="right-bar">
      <div
        ref="pageThumbs"
        class="page-thumbs-container"
        :class="!searchable && 'mt-10'"
        @scroll="thumbnailScroll"
      >
        <v-tooltip
          v-if="searchable"
          bottom
        >
          <template #activator="{ props }">
            <v-btn
              id="docSearchButton"
              class="small-button search-button"
              color="primary"
              v-bind="props"
              @click="searchOn = !searchOn"
            >
              <v-icon size="16">
                fas fa-search
              </v-icon>
            </v-btn>
          </template>
          {{ $t('models.search_text') }}
        </v-tooltip>
        <div
          v-if="filteredPages && filteredPages.length === 0 && !loadingThumbs"
          class="page-thumb-placeholder"
        >
          <span class="thumb-inside-text">
            {{ $t('docTypes.no_results') }}
          </span>
        </div>
        <div
          v-if="loadingThumbs"
          class="page-thumb-placeholder"
        >
          <div class="thumb-inside-text">
            <v-icon color="white">
              fas fa-spinner fa-pulse
            </v-icon>
          </div>
        </div>
        <div
          v-if="!loadingThumbs"
          ref="visibleThumbs"
          style="margin-top: 5px;"
        >
          <div
            v-for="page in filteredPages"
            :key="page.page_nb"
            class="page-thumb-container"
            :class="{
              'page-thumb-container-active': page.page_nb === currentPage,
            }"
            @click="currentPage = page.page_nb"
            @mouseenter="thumbnailHover = page.page_nb"
            @mouseleave="thumbnailHover = -1"
          >
            <div
              class="background-spread"
              :class="{
                'page-thumb': !!getThumbImage(page.page_nb),
                'page-thumb-placeholder': !getThumbImage(page.page_nb),
                'mb-2': !getThumbImage(page.page_nb),
              }"
              :style="{
                width: `${thumbWidth}vh`,
                height: `${getThumbHeight(page.page_nb)}vh`,
                'background-image':
                  getThumbImage(page.page_nb) ?
                    `url(${getThumbImage(page.page_nb)})` :
                    'initial',
              }"
            >
              <div
                v-if="!getThumbImage(page.page_nb)"
                class="thumb-inside-text"
              >
                <v-icon color="white">
                  fas fa-spinner fa-pulse
                </v-icon>
              </div>
              <div
                v-if="type === 'annotation' && thumbnailHover === page.page_nb && filteredPages.length > 1"
                class="delete-page-button"
                @click="deletePageDialog = true"
              >
                <v-icon
                  size="12"
                  style="margin-top: -2px"
                >
                  fas fa-times
                </v-icon>
              </div>
            </div>
            <div>{{ page.page_nb }}</div>
          </div>
        </div>
      </div>
      <div
        v-if="
          document.pages &&
            document.pages.length > 0 &&
            !(type === 'validator' && selectedTab === 'text')
        "
        class="zoom-buttons"
      >
        <v-tooltip
          v-if="(['validator', 'review'].includes(type) && userRole === 'orgadmin')"
          class="inline-middle"
          bottom
        >
          <template #activator="{ props }">
            <v-btn
              variant="text"
              v-bind="props"
              @click="showAddPageToDatasetDialog = true"
            >
              <v-icon
                style="margin: 7px"
                start
              >
                fas fa-copy
              </v-icon>
            </v-btn>
          </template>
          {{ $t("dataset.page.add") }}
        </v-tooltip>
        <v-tooltip
          v-if="exportValues.length > 0 && !['annotation', 'review'].includes(type)"
          class="inline-middle"
          bottom
        >
          <template #activator="{ props }">
            <v-btn
              style="margin: 7px"
              variant="text"
              v-bind="props"
              @click="exportJson"
            >
              <v-icon
                class="ma-0"
                start
              >
                fas fa-file-export
              </v-icon>
            </v-btn>
          </template>
          {{ $t("docViewer.export_values") }}
        </v-tooltip>
        <div>
          <v-btn
            v-if="selectedTab !== 'text'"
            class="inline-middle"
            style="display: block; margin: 7px;"
            variant="text"
            @click="handleZoom(0.4)"
          >
            <v-icon>fas fa-search-plus</v-icon>
          </v-btn>
          <v-btn
            v-if="selectedTab !== 'text'"
            class="inline-middle"
            style="margin: 7px"
            variant="text"
            @click="handleZoom(-0.4)"
          >
            <v-icon>fas fa-search-minus</v-icon>
          </v-btn>
        </div>
        <v-btn
          v-if="selectedTab !== 'text'"
          class="inline-middle"
          style="display: block; margin: 7px;"
          variant="text"
          @click="rotatePage"
        >
          <v-icon>fas fa-redo</v-icon>
        </v-btn>
        <ShortcutDialog
          v-if="displayShowTokens"
          :shortcuts="filteredShortcuts"
        />
      </div>
    </div>
    <AddPageToDatasetDialog
      v-if="userRole === 'orgadmin'
        && ['validator', 'review'].includes(type)
      "
      v-model="showAddPageToDatasetDialog"
      style="z-index: 1000 !important;"
      :document="document"
      :document-type="documentType"
      :page="currentPage"
      @close="showAddPageToDatasetDialog = false"
    />
    <DeleteDialog
      v-model="deletePageDialog"
      :title="$t('models.remove_file_page')"
      :message="$t('models.remove_file_page_confirmation')"
      @confirm="deletePage()"
      @close="deletePageDialog = false"
    />
  </div>
</template>

<script>
import { nextTick } from 'vue';
import { http } from "@/plugins/axios";
import { AuthenticationUtils } from '@/utils/AuthenticationUtils';
import { ConfigAPI } from '@/API/extract/ConfigAPI';
import ValueHighlights from "@/components/extract/elements/DocViewer/ValueHighlights.vue";
import SingleValueHighlights from "@/components/extract/elements/DocViewer/SingleValueHighlights.vue";
import ReviewValueHighlight from "@/components/extract/elements/DocViewer/ReviewValueHighlight.vue";
import ReviewValues from "@/components/extract/elements/DocViewer/ReviewValues.vue";
import GroupValueHighlights from "@/components/extract/elements/DocViewer/GroupValueHighlights.vue";
import EntitiesHighlights from "@/components/extract/elements/DocViewer/EntitiesHighlights.vue";
import TokenHighlights from "@/components/extract/elements/DocViewer/TokenHighlights.vue";
import BlockHighlights from '@/components/extract/elements/DocViewer/BlockHighlights.vue';
import TableHighlights from '@/components/extract/elements/DocViewer/TableHighlights.vue';
import ObjectHighlights from "@/components/extract/elements/DocViewer/ObjectHighlights.vue";
import AnnotationHighlights from "@/components/extract/elements/DocViewer/AnnotationHighlights.vue";
import SearchHighlights from "@/components/extract/elements/DocViewer/SearchHighlights.vue";
import ReadingOrder from "@/components/extract/elements/DocViewer/ReadingOrder.vue";
import DocSearch from "@/components/extract/elements/DocViewer/DocSearch.vue";
import CanvasSide from "@/components/extract/elements/DocViewer/CanvasSide.vue";
import DocText from "@/components/extract/elements/DocViewer/DocText.vue";
import AnnotationToolbox from "@/components/extract/elements/DocViewer/AnnotationToolbox.vue";
import AddPageToDatasetDialog from '@/components/extract/elements/DocViewer/AddPageToDatasetDialog.vue';
import ShortcutDialog from "@/components/extract/elements/DocViewer/ShortcutDialog.vue";
import ExtractorConfigToolbox from "@/components/extract/elements/DocViewer/ExtractorConfigToolbox.vue";
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import CustomSelect from "@/components/common/elements/Forms/CustomSelect.vue";

export default {
  name: "DocViewer",

  components: {
    EntitiesHighlights,
    TokenHighlights,
    BlockHighlights,
    TableHighlights,
    ObjectHighlights,
    ReadingOrder,
    AddPageToDatasetDialog,
    AnnotationHighlights,
    AnnotationToolbox,
    ShortcutDialog,
    ExtractorConfigToolbox,
    ValueHighlights,
    SingleValueHighlights,
    ReviewValues,
    ReviewValueHighlight,
    GroupValueHighlights,
    SearchHighlights,
    DocText,
    DeleteDialog,
    DocSearch,
    CanvasSide,
    CustomSelect,
  },

  data() {
    return {
      deletePageDialog: false,
      thumbnailHover: -1,
      baseWidth: 62,
      controlPressed: false,
      shiftPressed: false,
      config: {
        datascience: false,
      },
      cornerX: "right",
      cornerY: "bottom",
      currentPage: 1,
      drawing: false,
      forceCompute: Math.random(),
      imagesLoading: false,
      loadingThumbs: false,
      minZoom: 0.9,
      maxZoom: 4.2,
      pageDeletionTimeout: null,
      pageImages: {},
      rotation: 0,
      selectedTab: "values",
      fetchingThumbnails: false,
      shortcuts: [
        {
          keys: "Ctrl+Space",
          name: "show_words",
          allowedTypes: ["annotation", "review", "validator"],
        },
        {
          keys: "Ctrl+Shift+S",
          name: "search_text",
          allowedTypes: ["annotation", "review", "validator"],
        },
        {
          keys: "Ctrl+M",
          name: "toggle_mode",
          allowedTypes: ["annotation"],
        },
        {
          keys: "Ctrl+Z",
          name: "undo_action",
          allowedTypes: ["annotation"],
        },
        {
          keys: "Ctrl+D",
          name: "copy_filename",
          allowedTypes: ["annotation"],
        },
        {
          keys: "Ctrl+Y",
          name: "redo_action",
          allowedTypes: ["annotation"],
        },
        {
          keys: "Enter",
          name: "toggle_annotation",
          allowedTypes: ["annotation"],
        },
        {
          keys: "Esc.",
          name: "finish_annotation",
          allowedTypes: ["annotation"],
        },
        {
          keys: "↑",
          name: "prev_label",
          allowedTypes: ["annotation"],
        },
        {
          keys: "↓",
          name: "next_label",
          allowedTypes: ["annotation"],
        },
        {
          keys: "←",
          name: "prev_label",
          allowedTypes: ["annotation"],
        },
        {
          keys: "→",
          name: "next_label",
          allowedTypes: ["annotation"],
        },
        {
          keys: "Tab",
          name: "next_page",
          allowedTypes: ["annotation"],
        },
        {
          keys: "Ctrl+↑",
          name: "prev_page",
          allowedTypes: ["annotation"],
        },
        {
          keys: "Ctrl+↓",
          name: "next_page",
          allowedTypes: ["annotation"],
        },
        {
          keys: "Ctrl+←",
          name: "prev_page",
          allowedTypes: ["annotation"],
        },
        {
          keys: "Ctrl+→",
          name: "next_page",
          allowedTypes: ["annotation"],
        },
        {
          keys: "Ctrl+Enter",
          name: "next_doc",
          allowedTypes: ["annotation", "validator"],
        },
      ],
      computePages: 0,
      showAddPageToDatasetDialog: false,
      showEntities: false,
      showReadingOrder: false,
      selectedReadingOrder: "",
      showTokens: false,
      showBlocks: false,
      showTables: false,
      useTables: false,
      thumbImages: {},
      thumbWidth: 7,
      updatedCoordinates: {
        x_min: 0,
        x_max: 0,
        y_min: 0,
        y_max: 0,
      },
      zoom: 1,
      mountingFinished: false,
      loadingDisplayDelayPassed: true,
      oldThumbScrollTop: 0,
      searchOn: false,
    };
  },

  computed: {
    isDatascientist() {
      const user = this.$store.getters.loggedInUser;
      if (user) {
        return user.is_datascientist;
      }
      return false;
    },

    searchable() {
      return ['validator', 'review', 'annotation'].includes(this.type);
    },

    searchResource() {
      return this.type === 'annotation' ? 'entry' : 'files';
    },

    noDocsFound() {
      return this.mountingFinished && Object.keys(this.document).length <= 2;
    },

    pageLoaded() {
      return (
        !this.loading
        && !this.imagesLoading
        && !!this.document.pages
        && this.document.pages.length > 0
        && this.containerBaseWidth !== 0
      );
    },

    availableInfo() {
      return [
        'none',
        false,
        false,
        false,
        false,
        this.hasText ? 'text' : false,
        this.hasValues ? 'values' : false,
      ].filter(i => i);
    },

    userRole() {
      const user = this.$store.getters.loggedInUser;
      if (user) {
        return user.role || '';
      }
      return '';
    },

    filteredShortcuts() {
      return this.shortcuts.filter((item) => item.allowedTypes.includes(this.type));
    },

    visibleThumbnailsLimit() {
      if (this.document) {
        if (this.document.pages.length < 5) {
          return this.document.pages.length;
        }
        return 5;
      }
      return 0;
    },

    visibleThumbnails() {
      this.forceCompute;
      return this.filteredPages.filter(
        (page) => this.thumbImages[page.page_nb]
      );
    },

    thumbsContainerHeight() {
      this.visibleThumbnails;
      return this.$refs.pageThumbs ? this.$refs.pageThumbs.offsetHeight : 0;
    },

    visibleThumbsContainerHeight() {
      this.visibleThumbnails;
      const container = this.$refs.visibleThumbs;
      return container ? container.offsetHeight : 0;
    },

    filteredPages() {
      this.computePages;
      const docSearch = this.$refs.docSearch;
      if (docSearch && docSearch.searchText) {
        return this.document.pages.filter(
          (page) => docSearch.searchResultsPages.includes(page.page_nb)
        );
      }
      return this.document.pages || [];
    },

    filteredSearchResults() {
      this.computePages;
      const docSearch = this.$refs.docSearch;
      if (docSearch && docSearch.searchText) {
        const pageResults = docSearch.searchResults.find(
          (r) => r.page_nb === this.currentPage
        );
        if (pageResults) {
          return pageResults.words;
        }
        return [];
      }
      return [];
    },

    displayShowTokens() {
      if (
        ['annotation', 'validator', 'review'].includes(this.type) &&
        !this.loading &&
        this.document &&
        this.document.id > 0
      ) {
        return 'block';
      }
      return 'none';
    },

    displayShowEntities() {
      if (
        this.type === 'validator' &&
        !this.loading &&
        this.document &&
        this.document.id > 0
      ) {
        return 'block';
      }
      return 'none';
    },

    exportValues() {
      return [...this.rawValues, ...this.rawGroupValues];
    },

    customEntities() {
      this.forceCompute;
      if (
        this.document.id !== -1 &&
        this.document.customEntities &&
        this.document.customEntities.length > 0
      ) {
        return this.document.customEntities[this.currentPage - 1];
      }
      return [];
    },

    tokens: {
      get() {
        this.forceCompute;
        if (
          this.document.id !== -1 &&
          this.document.words
        ) {
          return this.document.words[this.currentPage - 1];
        }
        return [];
      },
      set() {
        // pass
      }
    },

    blocks: {
      get() {
        this.forceCompute;
        if (
          this.document.id !== -1 &&
          this.document.blocks
        ) {
          return this.document.blocks[this.currentPage - 1];
        }
        return [];
      },
      set() {
        // pass
      }
    },

    tables: {
      get() {
        this.forceCompute;
        if (
          this.document.id !== -1 &&
          this.document.tables
        ) {
          return this.document.tables[this.currentPage - 1];
        }
        return [];
      },
      set() {
        // pass
      }
    },

    pageTop() {
      let top;
      switch (this.rotation) {
        case 90:
          top = this.getRightTop(); break;
        case 180:
          top = this.getReversedTop(); break;
        case 270:
          top = this.getLeftTop(); break;
        default:
          top = this.getDefaultTop();
      }
      return top;
    },

    pageLeft() {
      let left;
      switch (this.rotation) {
        case 90:
          left = this.getTurnedLeft(); break;
        case 180:
          left = this.getReversedLeft(); break;
        case 270:
          left = this.getTurnedLeft(); break;
        default:
          left = this.getDefaultLeft();
      }
      return left;
    },

    horizontalMiddleTranslate() {
      let translate;
      switch (this.rotation) {
        case 90:
          translate = this.getRightHorizontalTranslate(); break;
        case 180:
          translate = 'translateX(-50%)'; break;
        case 270:
          translate = this.getLeftHorizontalTranslate(); break;
        default:
          translate = this.getDefaultHorizontalTranslate();
      }
      return translate;
    },

    verticalMiddleTranslate() {
      let translate;
      switch (this.rotation) {
        case 90:
          translate = this.getRightVerticalTranslate(); break;
        case 180:
          translate = this.getReversedVerticalTranslate(); break;
        case 270:
          translate = this.getLeftVerticalTranslate(); break;
        default:
          translate = this.getDefaultVerticalTranslate();
      }
      return translate;
    },

    pageShadow() {
      let shadow;
      switch (this.rotation) {
        case 90:
          shadow = '10px 0px'; break;
        case 180:
          shadow = '0px -10px'; break;
        case 270:
          shadow = '-10px 0px'; break;
        default:
          shadow = '0px 10px';
      }
      return shadow;
    },

    rotatedToPortrait() {
      return (
        this.isPortrait && [0, 360, 180].includes(this.rotation) ||
        !this.isPortrait && [90, 270].includes(this.rotation)
      )
    },

    annotationCoordinates: {
      get() {
        this.forceCompute;
        if (!this.document.annotations) {
          return [];
        }
        return Object.entries(this.document.annotations).reduce(
          (res, [, annotations]) => {
            let coordinates = annotations.map((annotation) => {
              return annotation.location[0];
            });
            res = [...res, ...coordinates];
            return res;
          },
          []
        );
      },
    },

    entityCoordinates() {
      this.forceCompute;
      return this.pageValues.map(dp => {
        if (dp.value.status === 'invalid' && dp.value.valid_location) {
          return dp.value.valid_location;
        }
        if (dp.value.location) {
          return dp.value.location;
        }
        return null;
      }).filter(c => c);
    },

    maxY() {
      this.document;
      this.zoom;
      this.currentPage;
      return this.displayPageHeight / this.pageHeight;
    },

    maxX() {
      this.document;
      this.zoom;
      this.currentPage;
      return this.displayPageWidth / this.pageWidth;
    },

    currentPageElement() {
      this.document;
      this.zoom;
      this.currentPage;
      this.rotation;
      return this.$refs.currentPage;
    },

    displayPageHeight() {
      return this.currentPageElement ? this.currentPageElement.clientHeight : 0;
    },

    displayPageWidth() {
      return this.currentPageElement ? this.currentPageElement.clientWidth : 0;
    },

    containerSideWidth() {
      this.document;
      this.zoom;
      this.currentPage;
      this.rotation;
      if ([0, 180, 360].includes(this.rotation)) {
        return (this.pageContainerWidth - this.displayPageWidth) / 2;
      }
      return (this.pageContainerWidth - this.displayPageHeight) / 2;
    },

    containerTopHeight() {
      this.document;
      this.zoom;
      this.currentPage;
      this.rotation;
      if ([0, 180, 360].includes(this.rotation)) {
        return (
          (this.pageContainerHeight - this.displayPageHeight) / 2
        );
      }
      return (
        (this.pageContainerHeight - this.displayPageWidth) / 2
      ) + 40;
    },

    containerBaseWidth() {
      this.document;
      this.zoom;
      this.currentPage;
      this.rotation;
      return this.pageContainerWidth * 100 / window.innerHeight;
    },

    containerBaseHeight() {
      this.loading;
      this.document;
      this.zoom;
      this.currentPage;
      this.rotation;
      return this.pageContainerHeight * 100 / window.innerHeight;
    },

    containerWidth() {
      this.zoom;
      this.currentPage;
      this.rotation;
      this.document;
      if (this.$refs.pageContainer) {
        if ([0, 180, 360].includes(this.rotation)) {
          return Math.max(
            this.pageContainerWidth,
            this.displayPageWidth
          );
        }
        return Math.max(
          this.pageContainerWidth,
          this.displayPageHeight
        );
      }
      return 0;
    },

    pageValue() {
      const values = this.viewerValues.filter(
        (value) => value.page_nb === this.currentPage
      );
      if (values.length > 0) {
        return {
          data_point_name: values[0].dp_name,
          value: { ...values[0], status: "valid" },
        };
      }
      return {};
    },

    pageValues() {
      if (this.currentPage) {
        if (this.type === 'configureGroup' && this.selectedTab === 'values') {
          return this.viewerValues.filter(
            (value) => {
              return value.values.length > 0 &&
              (value.values[0].valid_page_nb === this.currentPage ||
                (!value.values[0].valid_page_nb &&
                  value.values[0].page_nb === this.currentPage))
            }
          );
        }
        return this.viewerValues.filter(
          (value) => {
            return value.value.valid_page_nb === this.currentPage ||
            (!value.value.valid_page_nb &&
              value.value.page_nb === this.currentPage)
          }
        );
      }
      return [];
    },

    area() {
      const area =
        (this.updatedCoordinates.x_max - this.updatedCoordinates.x_min) *
        (this.updatedCoordinates.y_max - this.updatedCoordinates.y_min);
      return area;
    },

    defaultTab() {
      return this.hasValues ? "values" : "none";
    },

    pageContainerHeight() {
      this.document;
      const pageContainer = this.$refs.pageContainer;
      if (pageContainer) {
        return pageContainer.clientHeight;
      }
      return 0;
    },

    pageContainerWidth() {
      this.zoom;
      this.document;
      const pageContainer = this.$refs.pageContainer;
      return pageContainer ? pageContainer.clientWidth : 0;
    },

    currentPageData() {
      const page = this.getPageData(this.currentPage);
      return page || {
        dimension: {
          width: 1,
          height: 1,
        }
      }
    },

    pageImage() {
      this.forceCompute;
      if (
        this.document &&
        this.document.pages.length > 0 &&
        this.pageImages && this.pageImages[this.currentPage]
      ) {
        return this.pageImages[this.currentPage];
      }
      return "";
    },

    pageHeight() {
      let base;
      const ratio = this.getRatio(this.currentPage);
      if (this.isPortrait) {
        base = (window.innerHeight - (['validator'].includes(this.type) ? 91 : 40)) * 100 / window.innerHeight;
      } else {
        base = this.baseWidth * ratio;
      }
      return base * this.zoom;
    },

    pageWidth() {
      const ratio = this.getRatio(this.currentPage);
      if (this.isPortrait) {
        return this.pageHeight / ratio;
      }
      return this.baseWidth * this.zoom;
    },

    isPortrait() {
      return this.getRatio(this.currentPage) > 1;
    },

    pageContainerStyle() {
      return {
        height: this.config.datascience ? 'calc(100% - 85px)' : 'calc(100% - 40px)',
        'margin-top': this.config.datascience ? '85px' : '40px'
      };
    },
  },

  watch: {
    highlightedValue(value) {
      if (value.pageNb) {
        this.currentPage = value.pageNb;
      }
    },

    async document(newDoc, oldDoc) {
      if (newDoc.id != -1 && newDoc.id !== oldDoc.id) {
        if (this.mountingFinished) {
          this.loadingDisplayDelayPassed = false;
          setTimeout(() => {
            this.loadingDisplayDelayPassed = true;
          }, 200);
        }
        this.imagesLoading = true;
        if (this.type == "configureDP" || this.type == "configureGroup"){
          this.selectedReadingOrder = "default";
          this.updateReadingOrder();
        }
        this.showReadingOrder = false;
        this.showTokens = false;
        this.showBlocks = false;
        this.showTables = false;
        this.zoom = 1;
        this.rotation = 0;
        this.pageImages = {};
        this.thumbImages = {};
        this.showEntities = false;
        await this.getThumbImages();
        const docSearch = this.$refs.docSearch;
        if (docSearch) {
          await docSearch.getSearchResults();
        }
        this.currentPage = this.document.pages[0] && this.document.pages[0].page_nb || 1;
        await this.getPageImage(this.currentPage);
        if (this.pageImages[this.currentPage]) {
          this.imagesLoading = false;
        }
        await this.getThumbImages(this.visibleThumbnails.length, this.filteredPages.length);
      }
    },

    async filteredPages(filtered) {
      if (
        filtered.length < this.visibleThumbnailsLimit
        && filtered.length >= this.visibleThumbnails.length
      ) {
        const missing = this.filteredPages.filter(
          (page) => !this.thumbImages[page.page_nb]
        ).map(p => p.page_nb);
        for (let page of missing.slice(0, this.visibleThumbnailsLimit)) {
          const response = await this.fetchThumbImage(page);
          if (!response) {
            break;
          }
          this.thumbImages[page] = URL.createObjectURL(new Blob([response.data]));
        }
        this.forceCompute = Math.random();
      }
    },

    thumbnailHover(value) {
      const docSearch = this.$refs.docSearch;
      if (
        docSearch
        && docSearch.searchText
        && value !== -1
      ) {
        this.currentPage = value;
      }
    },

    async currentPage(value) {
      if (this.showReadingOrder){
        this.eraseSVG();
      }
      this.$emit('changePage');
      if (!this.pageImages[value]) {
        await this.getPageImage(value);
      }
    },

    containerBaseWidth(width) {
      if (width === 0) {
        this.computeSize();
      }
    },

    hasValues() {
      this.setDefaultTab();
    },

    activeTab(tab) {
      if (tab === "entities" && this.hasEntities) {
        this.selectedTab = "entities";
      } else if (tab === "form" && this.hasForms) {
        this.selectedTab = "form";
      } else if (tab === "layout" && this.hasLayout) {
        this.selectedTab = "layout";
      } else if (tab === "tables" && this.hasTables) {
        this.selectedTab = "tables";
      } else if (tab === "default") {
        this.selectedTab = this.defaultTab;
      }
    },

    showReadingOrder(show){
      this.$nextTick(() => {
        if (show){
          if (this.type == "annotation"){
            this.selectedReadingOrder = "roma3";
            this.useTables = true;
          }
          else {
            this.selectedReadingOrder = "default";
            this.useTables = false;
          }
          this.handleZoom(-0.1);
        }
        else {
          this.eraseSVG();
          this.handleZoom(0.1);
        }
      });
    },

    selectedReadingOrder() {
      nextTick(() => {
        this.updateReadingOrder();
      });
    },

    useTables() {
      nextTick(() => {
        this.updateReadingOrder();
      });
    },

    showEntities(show) {
      if (show) {
        this.getCustomEntities();
      }
    },

    showTokens(show) {
      if (show) {
        this.getTokens();
      }
    },

    showBlocks(show) {
      if (show) {
        this.getBlocks();
      }
    },

    showTables(show) {
      if (show) {
        this.getTables();
      }
    },

    visibleThumbsContainerHeight() {
      if (this.$refs.visibleThumbs) {
        const thumbnails = this.visibleThumbnails;
        if (
          !this.loadingThumbs &&
          !this.fetchingThumbnails &&
          thumbnails.length === this.visibleThumbnailsLimit &&
          thumbnails.length < this.filteredPages.length &&
          this.visibleThumbsContainerHeight < this.thumbsContainerHeight
        ) {
          this.getThumbImages(this.visibleThumbnails.length);
        }
      }
    }
  },

  async mounted() {
    this.setDefaultTab();
    this.imagesLoading = true;
    if (this.document.pages.length > 0) {
      this.currentPage = this.document.pages[0] && this.document.pages[0].page_nb || 1;
      await this.getPageImage(this.currentPage);
    }
    await this.getThumbImages();
    if (this.containerBaseWidth === 0) {
      this.computeSize();
    }
    this.imagesLoading = false;
    if (this.type == "annotation" || this.type == "validator"){
      await this.getConfig();
    }
    this.handleKeys();
    setTimeout(() => {
      this.mountingFinished = true;
    }, 600);
    window.addEventListener("click", this.hideDocSearch);
    await this.getThumbImages(this.visibleThumbnails.length, this.filteredPages.length);
  },

  unmounted() {
    this.stopKeyListening();
    window.removeEventListener("click", this.hideDocSearch);
  },

  methods: {
    hideDocSearch(event) {
      event.stopPropagation();      
      if (!this.searchable || !this.searchOn) {
        return;
      }
      const docSearch = this.$refs.docSearch;
      // Get the button with plain Javascript as Vue refs do not seem to work
      const docSearchButton = document.getElementById('docSearchButton');
      if (
        docSearch
        && docSearchButton
        && (
          !(docSearch.$el.contains(event.target) || docSearchButton.contains(event.target))
        )) {
        this.searchOn = false;
      }
    },

    getPageData(page_nb) {
      return this.document.pages.find(p => p.page_nb === page_nb);
    },

    async getConfig() {
      try {
        this.config = await ConfigAPI.get();
      } catch (err) {
        console.log(err);
      }
    },

    handleKeys() {
      window.addEventListener("keydown", this.handleKeydown);
      window.addEventListener("keyup", this.handleKeyup);
    },

    stopKeyListening() {
      window.removeEventListener("keydown", this.handleKeydown);
      window.removeEventListener("keyup", this.handleKeyup);
    },

    handleKeyup(event) {
      switch (event.code) {
        case "ControlLeft":
          this.handleAnnotationControlUp(event);
          break;
        case "ControlRight":
          this.handleAnnotationControlUp(event);
          break;
        case "ShiftLeft":
          this.handleShiftUp(event);
          break;
        case "ShiftRight":
          this.handleShiftUp(event);
          break;
      }
    },

    handleKeydown(event) {
      switch (event.code) {
        case "ControlLeft":
          this.handleAnnotationControl(event);
          break;
        case "ControlRight":
          this.handleAnnotationControl(event);
          break;
        case "ShiftLeft":
          this.handleShiftDown(event);
          break;
        case "ShiftRight":
          this.handleShiftDown(event);
          break;
        case "Space":
          if (this.controlPressed) {
            this.showTokens = !this.showTokens;
          }
          break;
        case "KeyS":
          if (this.controlPressed && this.shiftPressed) {
            event.preventDefault();
            const docSearch = this.$refs.docSearch;
            if (docSearch) {
              this.searchOn = !this.searchOn;
            }
          }
          break;
        default:
          this.controlPressed = false;
          this.shiftPressed = false;
          break;
      }
    },

    handleAnnotationControlUp(event) {
      event.preventDefault();
      this.controlPressed = false;
    },

    handleAnnotationControl(event) {
      event.preventDefault();
      this.controlPressed = true;
    },

    handleShiftDown(event) {
      event.preventDefault();
      this.shiftPressed = true;
    },

    handleShiftUp(event) {
      event.preventDefault();
      this.shiftPressed = false;
    },

    getDefaultTop() {
      if (this.isPortrait) {
        if (this.pageWidth >= this.containerBaseWidth) {
          return '0px';
        }
        return `${this.pageHeight / 2}vh`;
      } else {
        if (this.pageHeight >= this.containerBaseHeight) {
          return '0px';
        }
        return '50%';
      }
    },

    async getCustomEntities() {
      if (!this.document.customEntities) {
        try {
          this.$store.commit("setLoadingScreen", true);
          const response = await http.get(
            `files/${this.document.id}/info/`
          );
          const res = [];
          for (let i = 0; i < response.data.length; i++) {
            res.push([])
            let page = response.data[i];
            let models = Object.keys(page.custom_entities);
            for (let model of models) {
              let entities = Object.keys(page.custom_entities[model]);
              for (let entity of entities) {
                let values = page.custom_entities[model][entity];
                for (let value of values) {
                  value.model = model;
                  value.name = entity;
                  res[i].push(value);
                }
              }
            }
          }
          this.document.customEntities = res;
          this.forceCompute = Math.random();
        } catch (error) {
          console.log(error);
        } finally {
          this.$store.commit("setLoadingScreen", false);
        }
      }
    },

    async getTokens(force=false) {
      if (!this.document.words || force) {
        this.imagesLoading = true;
        let apiLocation;
        let extra_params = '';
        if (!['', 'default'].includes(this.selectedReadingOrder)){
          extra_params += `?custom_reading_order=${this.selectedReadingOrder}&`
        }
        if (this.useTables == true){
          extra_params += extra_params === '' && '?' || '';
          extra_params += `use_tables=${this.useTables}`
        }
        switch (this.type) {
          case "annotation":
            apiLocation = 'dataset/entry'; 
            break;
          case "review":
            apiLocation = 'production/files'; break;
          case "validator":
          case "configureDP":
          case "configureGroup":
            apiLocation = 'files'; break;
          default:
            apiLocation = '';
        }
        try {
          const response = await http.get(
            `${apiLocation}/${this.document.id}/words/${extra_params}`
          );
          this.document.words = response.data;
          this.forceCompute = Math.random();
        } catch (error) {
          console.log(error);
        } finally {
          this.imagesLoading = false;
        }
      }
    },

    async getBlocks() {
      let apiLocation;
      switch (this.type) {
        case "annotation":
          apiLocation = 'dataset/entry'; 
          break;
        case "validator":
        case "configureDP":
        case "configureGroup":
          apiLocation = 'files'; break;
        default:
          apiLocation = '';
      }
      try {
        const response = await http.get(
          `${apiLocation}/${this.document.id}/blocks/`
        );
        this.document.blocks = response.data;
        this.forceCompute = Math.random();
      } catch (error) {
        console.log(error);
      } finally {
        this.imagesLoading = false;
      }
    },

    async getTables() {
      let apiLocation;
      switch (this.type) {
        case "annotation":
          apiLocation = 'dataset/entry'; 
          break;
        case "validator":
        case "configureDP":
        case "configureGroup":
          apiLocation = 'files'; break;
        default:
          apiLocation = '';
      }
      try {
        const response = await http.get(
          `${apiLocation}/${this.document.id}/tables/`
        );
        this.document.tables = response.data;
        this.forceCompute = Math.random();
      } catch (error) {
        console.log(error);
      } finally {
        this.imagesLoading = false;
      }
    },

    getReversedTop() {
      if (this.isPortrait || this.pageHeight >= this.containerBaseHeight) {
        return `-${this.pageHeight}vh`;
      }
      return '50%';
    },

    getRightTop() {
      if (this.isPortrait && this.pageWidth >= this.containerBaseHeight || !this.isPortrait) {
        return `-${this.pageHeight}vh`;
      }
      return `-${this.pageWidth}vh`;
    },

    getLeftTop() {
      if (this.pageWidth >= this.containerBaseHeight || !this.isPortrait) {
        return `-${this.pageHeight}vh`;
      }
      return '-50%';
    },

    getDefaultLeft() {
      if (this.pageWidth >= this.containerBaseWidth) {
        return '0px';
      }
      return '50%';
    },

    getReversedLeft() {
      if (this.pageWidth >= this.containerBaseWidth) {
        return `${this.pageWidth / 2}vh`;
      }
      return '50%';
    },

    getTurnedLeft() {
      if (this.pageHeight >= this.containerBaseWidth) {
        return '0px';
      }
      return '50%';
    },

    getDefaultHorizontalTranslate() {
      if (this.pageWidth >= this.containerBaseWidth) {
        return '';
      }
      return `translateX(-50%)`;
    },

    getRightHorizontalTranslate() {
      if (this.pageWidth >= this.containerBaseHeight) {
        return '';
      }
      if (this.isPortrait) {
        return `translateX(${(9 / this.zoom) - (30 * this.zoom)}%)`;
      }
      return `translateX(${(43 / this.zoom) - (19 * this.zoom)}%)`;
    },

    getLeftHorizontalTranslate() {
      if (this.pageWidth >= this.containerBaseHeight) {
        return `translateX(-100%)`;
      }
      if (this.isPortrait) {
        return `translateX(${-(70 / this.zoom) + (23 * this.zoom)}%)`;
      }
      return `translateX(${-(106 / this.zoom) - (19 * this.zoom)}%)`;
    },

    getDefaultVerticalTranslate() {
      if (
        (this.isPortrait && this.pageWidth >= this.containerBaseWidth) ||
        (!this.isPortrait && this.pageHeight >= this.containerBaseHeight)
      ) {
        return '';
      }
      return 'translateY(-50%)';
    },

    getReversedVerticalTranslate() {
      if (this.isPortrait || this.pageHeight >= this.containerBaseHeight) {
        return '';
      }
      return `translateY(150%)`;
    },

    getRightVerticalTranslate() {
      if (this.pageHeight >= this.containerBaseWidth) {
        return '';
      }
      return 'translateY(50%)';
    },

    getLeftVerticalTranslate() {
      if (this.pageHeight >= this.containerBaseWidth) {
        return 'translateY(100%)';
      }
      return 'translateY(50%)';
    },

    computeSize() {
      const zoom = this.zoom;
      setTimeout(() => {
        this.zoom = 0;
        this.zoom = zoom;
      }, 10);
    },

    rotatePage() {
      this.rotation += 90;
      if (this.rotation >= 360) {
        this.rotation = 0;
      }
    },

    handleWheel(event) {
      if (this.controlPressed) {
        event.preventDefault();
        if (event.deltaY > 0) {
          this.handleZoom(0.1);
        } else if (event.deltaY < 0) {
          this.handleZoom(-0.1);
        }
      }
    },

    handleClick({ offsetX, offsetY, target }) {
      while (target.className !== this.$refs.currentPage.className) {
        offsetX += target.offsetLeft;
        offsetY += target.offsetTop;
        target = target.parentNode;
      }
      let relativeX = offsetX / this.displayPageWidth;
      let relativeY = offsetY / this.displayPageHeight;
      const originalX = relativeX;
      const originalY = relativeY;
      switch(this.rotation) {
        case 90:
          relativeX = 1 - originalY;
          relativeY = originalX;
          break;
        case 180:
          relativeX = 1 - originalX;
          relativeY = 1 - originalY;
          break;
        case 270:
          relativeX = originalY;
          relativeY = 1 - originalX;
          break;
        default:
          relativeX = originalX;
          relativeY = originalY;
          break;
      }
      this.scrollToView(relativeX, relativeY);
    },

    scrollToView(x, y) {
      const pageContainer = this.$refs.pageContainer;
      pageContainer.scrollLeft = x * this.displayPageWidth - (this.pageContainerWidth / 2);
      pageContainer.scrollTop = y * this.displayPageHeight - (this.pageContainerHeight / 2);
    },

    async getPageImage(page) {
      if (
        this.document.id > 0
        && !['uploaded', 'error'].includes(this.document.status)
      ) {
        const pageNb = page;
        try {
          const response = await http.get(
            `image/${this.resourceType}/${this.document.id}/${pageNb}/`,
            {
              params: {
                noAuth: this.externalToken !== "",
                external: this.externalToken !== "",
                token: this.externalToken,
              },
              responseType: "arraybuffer",
            }
          );
          this.pageImages[page] = URL.createObjectURL(new Blob([response.data]));
          this.forceCompute = Math.random();
        } catch (err) {
          console.log(err);
        }
      }
    },

    isElementVisible(el, parent) {
      const box = el.getBoundingClientRect();
      const parentBox = parent.getBoundingClientRect();
      return box.top <= parentBox.bottom && box.bottom >= parentBox.top;
    },

    async thumbnailScroll(e) {
      const { target: { scrollTop } } = e;
      const wheelDeltaY = scrollTop - this.oldThumbScrollTop;
      const pageThumbsContainer = this.$refs.pageThumbs;
      const visibleThumbsContainer = this.$refs.visibleThumbs;
      if (pageThumbsContainer && visibleThumbsContainer) {
        for (let i = 0; i < visibleThumbsContainer.children.length; i++) {
          const el = visibleThumbsContainer.children[i];
          if (
            !this.visibleThumbnails[i]
            && this.isElementVisible(el, pageThumbsContainer)
            && this.visibleThumbnails.length < this.filteredPages.length
          ) {
            const offset = wheelDeltaY > 0 ? i : i - 1 - this.visibleThumbnailsLimit;
            await this.getThumbImages(offset);
          }
        }
      }
      this.oldThumbScrollTop = scrollTop;
    },

    getThumbImage(page_nb) {
      return this.thumbImages[page_nb] || '';
    },

    async fetchThumbImage(pageNumber) {
      const token = this.externalToken || AuthenticationUtils.getToken();
      const response = await http.get(
        `image/thumbnail/${this.resourceType}/${this.document.id}/${pageNumber}/`,
        {
          params: {
            noAuth: this.externalToken !== "",
            external: this.externalToken !== "",
            token,
          },
          responseType: "arraybuffer",
          globalErrorHandlingOptOut: true,
        }
      );
      return response;
    },

    async getThumbImages(offset = 0, limit = this.visibleThumbnailsLimit) {
      if (this.document.id > 0) {
        const id = this.document.id;
        const pages = this.filteredPages;
        this.fetchingThumbnails = true;
        for (let page of pages.slice(offset, offset + limit)) {
          if (this.document.id !== id) {
            break;
          }
          const response = await this.fetchThumbImage(page.page_nb)
          if (!response) {
            break;
          }
          this.thumbImages[page.page_nb] = URL.createObjectURL(new Blob([response.data]));
        }
        this.forceCompute = Math.random();
        this.fetchingThumbnails = false;
      }
    },

    setDefaultTab() {
      this.selectedTab = this.defaultTab;
    },

    exportFile(response, filename) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    },

    async exportTables() {
      try {
        const response = await http.get(`files/${this.document.id}/tables/`, {
          responseType: "arraybuffer",
        });
        const fileName = this.document.name
          .replace(/,/g, "")
          .replace(/\.[^/.]+$/, ".xlsx");
        this.exportFile(response, fileName);
      } catch (error) {
        // pass
      }
    },

    async deletePage() {
      if (this.document.pages.length === 1) {
        if (this.pageDeletionTimeout) {
          clearTimeout(this.pageDeletionTimeout);
        }
        this.$store.commit("setErrorMessage", this.$t('dataset.page.cannot_delete_last'));
        this.$store.commit("setSnackbar", true);
        this.pageDeletionTimeout = setTimeout(
          () => this.$store.commit('setErrorMessage', this.$t('default_error')), 5000
        );
        this.$store.commit('setLoadingScreen', false);
        return;
      }
      try {
        await http.delete(`dataset/entry/${this.document.id}/pages/${this.currentPage}`);
        const indexToDelete = this.document.pages.findIndex(page => page.page_nb === this.currentPage);
        this.document.pages.splice(indexToDelete, 1);
        this.document.pages = [...this.document.pages];
        this.deletePageDialog = false;
        this.currentPage = this.document.pages[Math.min(indexToDelete, this.document.pages.length - 1)].page_nb;
        await this.getThumbImages();
        this.$emit('updateAnnotations');
        this.$store.commit('setSuccessMessage', this.$t('dataset.page.deleted'));
        this.$store.commit('setSuccessSnackbar', true);
      } catch (error) {
        // pass
      }
    },
    resetDrawing() {
      this.drawing = false;
      this.updatedCoordinates.x_min = -1;
      this.updatedCoordinates.x_max = -1;
      this.updatedCoordinates.y_min = -1;
      this.updatedCoordinates.y_max = -1;
    },
    drawStart(event) {
      this.drawing = true;
      const newX = event.offsetX / this.pageWidth;
      const newY = event.offsetY / this.pageHeight;
      this.updatedCoordinates.x_min = newX;
      this.updatedCoordinates.x_max = newX;
      this.updatedCoordinates.y_min = newY;
      this.updatedCoordinates.y_max = newY;
    },
    drawMove(event) {
      if (this.drawing) {
        this.updateX(event.offsetX / this.pageWidth);
        this.updateY(event.offsetY / this.pageHeight);
      }
    },

    updateY(newY) {
      if (this.cornerY === "bottom") {
        if (newY > this.updatedCoordinates.y_min) {
          this.updatedCoordinates.y_max = newY;
        } else {
          this.cornerY = "top";
        }
      } else if (this.cornerY === "top") {
        if (newY < this.updatedCoordinates.y_max) {
          this.updatedCoordinates.y_min = newY;
        } else {
          this.cornerY = "bottom";
        }
      }
    },
    updateX(newX) {
      if (this.cornerX === "right") {
        if (newX > this.updatedCoordinates.x_min) {
          this.updatedCoordinates.x_max = newX;
        } else {
          this.cornerX = "left";
        }
      } else if (this.cornerX === "left") {
        if (newX < this.updatedCoordinates.x_max) {
          this.updatedCoordinates.x_min = newX;
        } else {
          this.cornerX = "right";
        }
      }
    },
    drawEnd() {
      const heightUnit = window.innerHeight / 100;
      this.drawing = false;
      this.$emit("updateCoordinates", {
        page_nb: this.currentPage,
        data_point_id: this.activeDatapoint ? this.activeDatapoint.data_point_id : -1,
        location: {
          x_min: this.normalizeCoordinate(
            this.updatedCoordinates.x_min,
            heightUnit,
            this.pageWidth
          ),
          x_max: this.normalizeCoordinate(
            this.updatedCoordinates.x_max,
            heightUnit,
            this.pageWidth
          ),
          y_min: this.normalizeCoordinate(
            this.updatedCoordinates.y_min,
            heightUnit,
            this.pageHeight
          ),
          y_max: this.normalizeCoordinate(
            this.updatedCoordinates.y_max,
            heightUnit,
            this.pageHeight
          ),
        },
      });
    },
    normalizeCoordinate(x, heightUnit, length) {
      return (x * length * 100) / (heightUnit * length) / 100;
    },
    getHighlightX(x) {
      return x * this.pageWidth;
    },
    getHighlightY(y) {
      return y * this.pageHeight;
    },
    nextPage(indicator) {
      const lastPage = this.document.pages ? this.document.pages.length : 0;
      let nextPage = this.currentPage + indicator;
      if (nextPage > lastPage) {
        nextPage = 1;
      } else if (nextPage === 0) {
        nextPage = lastPage;
      }
      this.currentPage = nextPage;
    },
    handleZoom(zoom) {
      let newZoom = Math.round((this.zoom + zoom) * 10) / 10;
      if (newZoom < this.minZoom) {
        newZoom = this.minZoom;
      } else if (newZoom > this.maxZoom) {
        newZoom = this.maxZoom;
      }
      this.zoom = newZoom;
      this.$emit("zooming", newZoom);
    },

    getRatio(page_nb) {
      const pageData = this.getPageData(page_nb);
      if (pageData) {
        return pageData.dimension.height / pageData.dimension.width;
      }
      return 1;
    },

    getThumbHeight(page_nb) {
      return this.thumbWidth * this.getRatio(page_nb);
    },
    highlight(dataPoint) {
      this.$emit("highlight", dataPoint);
    },
    highlightNER(page_nb) {
      this.$emit("highlightNER", page_nb);
    },
    verify(value) {
      this.$emit("verify", value);
    },
    exportJson() {
      const data = JSON.stringify(this.exportValues);
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "text/plain" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "values.json");
      document.body.appendChild(link);
      link.click();
    },

    updateReadingOrder() {
      if (this.type != "configureDP" && this.type != "configureGroup"){
        this.eraseSVG();
      }
      this.getTokens(true);
    },

    drawSVG(svg) {
      this.$nextTick(() => {
        const page = document.getElementsByClassName("current-page")[0];
        if (page) {
          page.appendChild(svg);
        }
      });
    },

    eraseSVG(){
      const page = document.getElementsByClassName("current-page")[0];
      const svgs = page.getElementsByTagName("svg");
      while(svgs.length > 0){
        svgs[0].remove();
      } 
    },
  },

  props: {
    activeTab: {
      type: String,
      required: false,
      default: "",
    },
    document: {
      type: Object,
      required: true,
    },
    documentType: {
      type: Object,
      default: () => {},
    },
    activeDatapoint: {
      type: Object,
      required: false,
      default: () => {},
    },
    highlightedObject: {
      type: Object,
      required: false,
      default: () => {},
    },
    type: {
      type: String,
      default: "configureDP",
    },
    tableNum: {
      type: Number,
      default: -1,
    },
    tabs: {
      type: Boolean,
      default: false,
    },
    layoutCategories: {
      type: Array,
      default: () => [],
    },
    entities: {
      type: Array,
      default: () => [],
    },
    viewerValues: {
      type: Array,
      default: () => [],
    },
    verified: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasTables: {
      type: Boolean,
      default: false,
    },
    hasText: {
      type: Boolean,
      default: false,
    },
    hasLayout: {
      type: Boolean,
      default: false,
    },
    hasEntities: {
      type: Boolean,
      default: false,
    },
    hasForms: {
      type: Boolean,
      default: false,
    },
    hasValues: {
      type: Boolean,
      default: true,
    },
    textSelect: {
      type: Boolean,
      default: false,
    },
    onlyShowLabels: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
    resourceType: {
      type: String,
      default: "file",
    },
    rawValues: {
      type: Array,
      default: () => [],
    },
    objectValues: {
      type: Array,
      default: () => [],
    },
    rawGroupValues: {
      type: Array,
      default: () => [],
    },
    externalToken: {
      type: String,
      default: "",
    },
    labelType: {
      type: String,
      default: '',
    },
    datasetInfo: {
      type: Object,
      default: () => {},
    },
    reviewValues: {
      type: Array,
      required: false,
      default: () => [],
    },
    highlightedValue: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  emits: [
    'deHighlight',
    'deleteAnnotation',
    'selectLabel',
    'changeLabel',
    'updateAnnotations',
    'updateCoordinates',
    'zooming',
    'highlight',
    'highlightNER',
    'verify',
    'changePage',
    'handleObjectEnter', 
    'handleObjectLeave'
  ],
};
</script>

<style scoped lang="scss">

.delete-page-button {
  top: 7px;
  right: 0px;
  position: absolute;
  transform: translate(-50%, -50%);
  padding-left: 3px !important;
  padding-right: 3px !important;
  box-shadow: none;
  border-radius: 50%;
  text-align: center;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background-color: rgb(var(--v-theme-primary));
  color: white;
}

.doc-box {
  width: 50%;
  display: inline-block;
  text-align: left;
  vertical-align: top;
  position: relative;

  .page-container {
    display: inline-block;
    width: 90%;
    height: calc(100% - 40px);
    background-color: rgb(var(--v-theme-grey-darken3));
    text-align: center;
    vertical-align: top;
    position: relative;
    overflow: auto;
    margin-top: 40px;

    .current-page {
      background-color: white;
      position: absolute;
      transform-origin: bottom left;
    }
  }

  .coordinate-box {
    border: rgb(var(--v-theme-primary)) solid 3px;
    position: absolute;
    background-color: #4e2bff44;
  }

  .loading-page {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: rgb(var(--v-theme-grey-darken3));
    opacity: 0.5;
  }

  .top-bar {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 90%;
    height: 40px;
    background-color: #969696;
    color: white;
    padding-left: 25px;
    font-size: 0.9rem;
    z-index: 7 !important;

    .doc-tabs {
      position: absolute;
      top: 0px;
      right: 0px;
    }
  }

  .datascience-bar {
    position: absolute;
    top: 40px;
    left: 0px;
    width: 100%;
    padding: 5px 0;
    height: 45px;
    background-color: gray;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
  }

  .background-spread {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .zoom-buttons {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    text-align: center;
    width: 100%;
    margin-bottom: 2vh;
    padding-top: 20px;
  }

  .table-button {
    position: absolute;
    right: 40px;
    bottom: 1vh;
  }
}

.right-bar {
  display: inline-flex;
  flex-direction: column;
  width: 10%;
  background-color: rgb(var(--v-theme-grey-darken2));
  height: 100%;
  position: relative;
}

.page-thumbs-container {
  width: 100%;
  display: inline-block;
  height: 100%;
  text-align: center;
  vertical-align: top;
  overflow: auto;
  position: relative;

  .page-thumb-container {
    font-size: 0.9rem;
    margin-top: 0;
    margin-bottom: 2vh;
    cursor: pointer;
    position: relative;

    .page-thumb {
      background-color: white;
      display: inline-block;
      opacity: 0.5;
    }
  }

  .page-thumb-container-active {
    .page-thumb {
      border: 2px solid rgb(var(--v-theme-primary));
      opacity: 1;
    }
  }

  .page-thumb-container:hover {
    .page-thumb {
      opacity: 1;
    }
  }
}

.page-thumb-placeholder {
  width: calc(70%) !important;
  padding-top: 100%;
  margin: 0 auto;
  margin-top: 5px;
  margin-bottom: calc(2vh + 22px);
  background-color: #dedede;
  font-size: 10px;
  position: relative;
}

.thumb-inside-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.show-tokens-box {
  width: 100%;
  position: absolute;
  left: 0;
  padding: 0 10px;
}

.top-bar :v-deep(.v-label) {
  color: white !important;
}

.disable-horizontal-scroll {
  max-width: calc(100%);
  overflow-x: hidden;
}

.search-button {
  position: sticky;
  top: 7px;
  margin: 5px auto;
  z-index: 900;
  box-shadow: none;
}
</style>
