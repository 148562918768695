<template>
  <div class="label">
    {{ $t('models.labels') }}
    <v-chip
      class="ml-2"
      style="background-color: #CCC2FF !important"
      variant="outlined"
    >
      {{ labels && labels.length || 0 }}
      {{ $tc('models.added_labels', labels && labels.length || 0) }}
    </v-chip>
  </div>
  <v-card
    v-if="labels && labels.length > 0"
    class="top-gap label-card py-0 px-0"
  >
    <v-row class="label-row font-weight-bold">
      <v-col class="d-flex align-center py-0">
        {{ $t('models.labels') }}
      </v-col>
      <v-col class="d-flex align-center text-center py-0">
        {{ $t('datatable.header.value_type') }}
      </v-col>
    </v-row>
    <v-row
      v-for="(label, index) in labels"
      :key="index"
      class="label-row"
    >
      <v-col style="overflow-wrap: anywhere">
        <v-tooltip right>
          <template #activator="{ props }">
            <span
              v-bind="props"
              style="position: relative; top: 3px"
            >
              {{ label }}
            </span>
          </template>
          {{ label }}
        </v-tooltip>
        <v-icon
          class="close-button clickable"
          color="black"
          size="14"
          @click="deleteLabel(index)"
        >
          fas fa-times
        </v-icon>
      </v-col>
      <v-col>
        <v-select
          class="inline-middle"
          style="width: 150px; margin-top: -5px;"
          variant="outlined"
          color="primary"
          density="compact"
          :model-value="labelValueTypes[index] ?? 'unspecified'"
          :items="valueTypes"
          @update:model-value="updateLabelValueType(index, $event)"
        />
      </v-col>
    </v-row>
  </v-card>
  <div
    v-else
    class="no-label-sign mt-5"
  >
    {{ $t('dataPoints.no_labels') }}
  </div>
  <div class="d-flex justify-space-between align-center top-gap">
    <v-text-field
      v-model="newLabel"
      class="inline-middle right-gap label-field"
      variant="outlined"
      color="primary"
      density="compact"
      :placeholder="$t('models.type_label_name')"
      @keydown.enter="addGenerativeLabel"
    />
    <v-btn
      class="add-button"
      color="primary"
      style="margin-top: -20px"
      :disabled="newLabel === ''"
      @click="addGenerativeLabel"
      rounded
    >
      <v-icon
        size="16"
        start
      >
        fas fa-plus
      </v-icon>
      {{ $t("docTypes.add") }}
    </v-btn>
  </div>
</template>
<script>
export default {
  name: 'GenerativeExtractorLabels',

  data() {
    return {
      newLabel: '',
      valueTypes: [
        {
          title: this.$t('datatable.value_type.unspecified'),
          value: 'unspecified',
        },
        {
          title: this.$t('datatable.value_type.date'),
          value: 'date',
        },
        {
          title: this.$t('datatable.value_type.integer'),
          value: 'integer',
        },
        {
          title: this.$t('datatable.value_type.float'),
          value: 'float',
        },
      ],
    };
  },

  methods: {
    addGenerativeLabel() {
      if (this.newLabel !== '') {
        this.$emit('updateLabels', [
          ...this.labels,
          this.newLabel,
        ]);
        this.$emit('updateValueTypes', [
          ...this.labelValueTypes,
          'unspecified',
        ]);
        this.newLabel = '';
      }
    },

    deleteLabel(index) {
      this.$emit('updateLabels', this.labels.filter((_, i) => i !== index));
      this.$emit('updateValueTypes', this.labelValueTypes.filter((_, i) => i !== index));
    },

    updateLabelValueType(index, value) {
      const updatedValueTypes = [...this.labelValueTypes];
      updatedValueTypes[index] = value;
      this.$emit('updateValueTypes', updatedValueTypes);
    },
  },

  props: {
    labels: {
      type: Array,
      default: () => [],
    },
    labelValueTypes: {
      type: Array,
      default: () => [],
    }
  },

  emits: [
    'updateLabels', 'updateValueTypes',
  ],
};
</script>
<style lang="scss" scoped>
.label-card {
  margin-bottom: -5px;
  padding: 0;
}

.label-row {
  margin: 0 !important;
  position: relative;
  height: 55px;
  border-bottom: 1px solid #eee;
}

.rounded-card {
  border-radius: 0.3125rem;
}

.no-label-sign {
  font-style: italic;
  text-align: center;
}

.info-box {
  background-color: rgb(var(--v-theme-primary-lighten2));
  border-radius: 6px;
  padding: 6px 17px;
  padding-bottom: 10px;
  width: fit-content;

  .info-icon {
    margin-right: 2px;
    top: -1px;
  }
}

.close-button {
  position: absolute;
  right: 7px;
  top: 7px;
  z-index: 3;
}
</style>
