<template>
  <TableWithFooter
    :loading="loading"
    :paginated-items-length="pages[currentPage]?.length ?? 0"
    :total="filteredItems.length"
    :current-page="currentPage"
    :items-per-page="itemsPerPage"
    @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
    @change-page="(page) => currentPage = page"
    include-footer-in-table-card
  >
    <template #header>
      <v-col cols="auto">
        <SortButton v-model="sortDesc" />
        <v-checkbox
          v-model="allSelected"
          class="mt-0"
          hide-details
        />
      </v-col>
      <v-col cols="4">
        {{ $t('datatable.header.dataPointName') }}
      </v-col>
      <v-col
        v-if="user.role === 'orgadmin'"
        cols="2"
      >
        {{ $t('datatable.header.value_type') }}
      </v-col>
      <v-col
        v-if="user.role === 'orgadmin'"
        style="text-align: center"
        cols="2"
      >
        {{ $t('datatable.header.precision') }}
      </v-col>
      <v-col
        v-if="user.role === 'orgadmin'"
        cols="3"
      >
        {{ $t('dataPoints.model') }}
      </v-col>
      <v-col
        v-if="user.role === 'sysadmin'"
        cols="3"
      >
        {{ $t('dataPoints.label') }}
      </v-col>
    </template>
    <template #body>
      <v-container
        class="pa-0"
        fluid
      >
        <v-row
          v-for="item in pages[currentPage]"
          :key="item.id"
          class="table-row fade-in table-row-height"
          style="border-bottom: 1px solid #eee !important"
        >
          <v-col cols="auto">
            <v-checkbox
              v-model="item.selected"
              class="left-gap mt-0"
              @change="$emit('setItems', [...allItems])"
              hide-details
            />
          </v-col>
          <v-col cols="4">
            <ItemName
              :key="item.id"
              :ref="`name_${item.id}`"
              :item="item"
              :editing-allowed="!!(item.selected)"
              :clickable="!disabled"
              @save-file-name="(id, newName) => {saveDPName(id, newName, item);}"
              @name-click="handleEditButton($route.params.id, item.id)"
            />
          </v-col>
          <v-col
            v-if="user.role === 'orgadmin'"
            cols="2"
            :class="{
              'clickable': !disabled,
              'primary--text': !disabled,
            }"
            @click="handleEditButton($route.params.id, item.id)"
          >
            {{ $t(`datatable.value_type.${item.value_type}`) }}
          </v-col>
          <v-col
            v-if="user.role === 'orgadmin'"
            style="text-align: center"
            cols="2"
          >
            <span v-if="(item.nb_valid_values + item.nb_invalid_values) > 0">
              {{ (item.nb_valid_values / (item.nb_valid_values + item.nb_invalid_values) * 100).toFixed(0) }}%
              ({{ item.nb_valid_values }}/{{ item.nb_valid_values + item.nb_invalid_values }})
            </span>
            <span v-else>
              —
            </span>
          </v-col>
          <v-col cols="3">
            <span v-if="user.role === 'orgadmin' && item.category === 'generative'">
              {{ $t('dataPoints.generative.title') }}
            </span>
            <div
              v-else-if="user.role === 'orgadmin' && item.category === 'custom' && item.model_name"
              style="width: calc(100% - 20px)"
              class="d-flex"
            >
              <ItemName
                :item="{ name: item.model_name }"
                :clickable="false"
                :editing-allowed="false"
                :show-id="false"
                :badge="`v${item.model_version}`"
              />
            </div>
            <div v-else-if="user.role === 'sysadmin'">
              <ItemName
                :item="{ name: `${getEntityInfo(item)}`}"
                :clickable="false"
                :editing-allowed="false"
                :show-id="false"
              />
            </div>
            <span v-else>
              —
            </span>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </TableWithFooter>
</template>

<script>
import ItemName from '@/components/common/elements/General/ItemName';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import SortButton from '@/components/common/elements/Tables/SortButton';
import { http } from '@/plugins/axios';

export default {
  name: 'DataPointsTable',

  components: {
    ItemName,
    TableWithFooter,
    SortButton,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      extractionMethodMap: {
        'custom': this.$t('dataPoints.model'),
        'classic': this.$t('dataPoints.rules'),
      },
      itemsPerPage,
      currentPage,
    };
  },

  computed: {
    user() {
      return this.$store.getters.loggedInUser;
    },
    isAdmin() {
      return ['orgadmin', 'sysadmin'].includes(this.user.role);
    },

    sortDesc: {
      get() {
        return this.$store.getters.dpSortDesc;
      },
      set(sortDesc) {
        this.$store.commit('setDpSortDesc', sortDesc);
      },
    },

    allSelected: {
      get() {
        if (this.pages[this.currentPage]) {
          return this.pages[this.currentPage].every(item => item.selected);
        }
        return false;
      },
      set(allSelected) {
        const selected = this.allItems.map(item => {
          if (this.pages[this.currentPage].includes(item)) {
            item.selected = allSelected;
          }
          return item;
        });
        this.$emit('setItems', [...selected]);
      }
    },

    selected: {
      get() {
        if (this.filteredItems.length > 0) {
          return this.filteredItems.filter(item => item.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },

    pages: {
      get() {
        const pages = {};
        let page = 0;
        if (this.itemsPerPage > 0) {
          this.filteredItems.forEach((item, i) => {
            if (!(i % this.itemsPerPage)) {
              page++;
              pages[page] = [];
            }
            pages[page].push(item);
          });
        } else {
          pages[1] = [...this.filteredItems];
        }
        return pages;
      },
      set() {
        // pass
      }
    },
  },

  watch: {
    sortDesc() {
      this.$emit('getItems', this.sortDesc);
    },

    itemsPerPage() {
      this.resetCurrentPage();
    },
  },

  methods: {
    resetCurrentPage() {
      this.currentPage = 1;
      const items = this.allItems.map(item => {
        item.selected = false;
        return item;
      });
      this.$emit('setItems', [...items]);
    },

    handleEditButton(id, itemId) {
      if (this.user.role === 'orgadmin' && !this.disabled) {
        {
          this.$router.push({
            name: 'DatapointConfig',
            params: {
              id, itemId,
            },
          });
        }
      }
    },

    async saveDPName(id, name, dp) {
      this.$store.commit('setLoadingScreen', true);
      try {
        await http.put(`system_2/data_point/${id}`, { name });
        this.allSelected = false;
        this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.updated'));
        this.$store.commit('setSuccessSnackbar', true);
        dp.name = name;
        this.$emit('saveName');
      } catch (error) {
        this.docLoading = false;
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    getEntityInfo(dp) {
      const eps = dp.extraction_paths;
      if (eps.length === 1) {
        const entity = eps[0].what.custom_entity;
        if (entity) {
          if (this.$te(`entities.${entity[0][1]}`)) {
            return this.$t(`entities.${entity[0][1]}`);
          }
          return entity[0][1];
        }
      }
      return '-';
    },
  },

  props: {
    allItems: {
      type: Array,
      required: true,
    },

    filteredItems: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['setItems', 'getItems', 'saveName', 'copyGenerative'],
}
</script>
