<template>
  <div
    class="document-view py-5"
    :class="{ 'document-view--open-doc-viewer': selectedPage }"
  >
    <div
      v-if="loaded"
      class="d-flex align-center"
    >
      <BackLink
        class="mb-2"
        :show-text="false"
        :target="{ name: 'Corrections', query: { activeTab: 'classificationReview' } }"
      />
      <HeaderName
        :item="{ name: document.filename, id: document.id }"
        :editable="false"
        @save="updateCollectionName"
      />
      <ClassificationValidation
        v-if="document && document.status !== 'uploaded'"
        v-model="document.category_id"
        :submitting-review="submittingReview"
        :display-categories="!document.is_bundle"
        :discard-reasons="discardReasons"
        :categories="model.categories"
        :default-discard-reason="$t('workflows.documents.file_corrupted')"
        @submit-review="submitReview"
        @submit-discard-reason="submitDiscardReview"
      />
    </div>
    <div
      v-if="mergedPages.length > 0 && loaded"
      class="d-flex flex-wrap top-gap fade-in"
      style="overflow: visible !important;"
    >
      <FileContainer
        v-for="merged, i in mergedPages"
        :key="i"
        :classified-pages="merged"
        :last="i === mergedPages.length - 1"
        :display-categories="document.is_bundle"
        :categories="model.categories"
        :category-map="categoryMap"
        :selected-page="selectedPage"
        @select-page="page => selectedPage = page"
      />
    </div>
    <div
      class="sidebar"
      :style="{width: `${sidebarWidth}px`}"
    >
      <v-icon
        class="clickable"
        style="position: absolute; left: 10px; top: 10px; color: #07002D"
        @click="selectedPage = null"
      >
        fas fa-chevron-right
      </v-icon>
      <div
        v-if="selectedPageImage"
        class="page background-spread fade-in clickable inline-middle vertical-centered"
        :style="{
          width: `${pageWidth}px`,
          height: `${pageHeight}px`,
          'background-image': `url(${selectedPageImage})`,
        }"
      />
    </div>
  </div>
</template>
<script>
import { http } from '@/plugins/axios';
import { ClassifyDocumentsAPI } from '@/API/classify/ClassifyDocumentsAPI';
import { ClassifyModelAPI } from '@/API/classify/ClassifyModelAPI';

import BackLink from '@/components/common/elements/Navigation/BackLink.vue';
import FileContainer from '@/components/extract/elements/Workflows/PageCorrection/FileContainer';
import HeaderName from '@/components/extract/elements/Workflows/PageCorrection/HeaderName';
import ClassificationValidation from '@/components/extract/views/Corrections/ClassificationValidation.vue';

export default {
  name: 'DocumentCorrectionView',

  components: {
    BackLink,
    FileContainer,
    HeaderName,
    ClassificationValidation,
  },

  data() {
    return {
      document: null,
      pages: [],
      model: {},
      statusCheck: null,
      loaded: false,
      downloading: false,
      updatedPages: [],
      updatingPages: false,
      selectedPage: null,
      pageImageMap: {},
      baseWidth: 500,
      submittingReview: false,
      discardReasons: this.getDiscardReasons(),
    }
  },

  computed: {
    categoryMap() {
        return (this.model?.categories || []).reduce((acc, category) => {
          acc[category.id] = category.name;
          return acc;
        }, {});
      },

    mergedPages() {
        return this.pages.reduce((acc, page) => {
          acc.reverse();
          const existing = acc.find(merged => merged.category_id === page.category_id);
          acc.reverse();
          if (existing) {
            const lastPage = existing.pages[existing.pages.length - 1];
            if (lastPage.page_number + 1 === page.page_number) {
              existing.pages.push(page);
            } else {
              acc.push({
                id: Math.random(),
                category_id: page.category_id,
                pages: [page],
              });
            }
          } else {
            acc.push({
              id: Math.random(),
              category_id: page.category_id,
              pages: [page],
            });
          }
          return acc;
        }, []);
    },

    selectedPageImage() {
      return this.selectedPage ? this.pageImageMap[this.selectedPage.id] : '';
    },

    ratio() {
      if (this.selectedPage && this.selectedPage.image_width && this.selectedPage.image_height) {
        return this.selectedPage.image_height / this.selectedPage.image_width;
      }
      return 1;
    },

    isPortrait() {
      return this.ratio > 1;
    },

    pageHeight() {
      const ratio = this.ratio;
      return this.baseWidth * ratio;
    },

    pageWidth() {
      const ratio = this.ratio;
      if (this.isPortrait) {
        return this.pageHeight / ratio;
      }
      return this.baseWidth;
    },

    sidebarWidth() {
      return this.selectedPage ? 600 : 0;
    },

  },

  watch: {
    selectedPage(page) {
      if (page) {
        if (!this.pageImageMap[page.id]) {
          this.getImage();
        }
      }
    },
  },

  async created() {
    await  this.getDocument(),
    await Promise.all([
      this.getModel(),
      this.getPages()
    ]);
    this.loaded = true;
  },

  umounted() {
    clearTimeout(this.statusCheck);
  },

  methods: {
    async getImage() {
      try {
        const data = await ClassifyDocumentsAPI.getPageImage(
          this.$route.params.id,
          this.selectedPage.id,
        );
        this.pageImageMap = {
          ...this.pageImageMap,
          [this.selectedPage.id]: URL.createObjectURL(new Blob([data])),
        };
      } catch (error) {
        // pass
      }
    },

    async getDocument() {
      try {
        this.document = await ClassifyDocumentsAPI.getDocument(this.$route.params.id);
      } catch (error) {
        this.error = true;
      }
    },

    async getModel() {
      try {
        this.model = await ClassifyModelAPI.getModel({modelId: this.document.model_id});
      } catch (error) {
        // pass
      }
    },

    async updateCollectionName(name) {
      try {
        this.document.name = name;
      } catch (error) {
        this.$store.commit('setSnackbar', true);
      }
    },

    async getPages() {
      try {
        const data = await ClassifyDocumentsAPI.getPages(this.document.id);
        this.pages = data.sort((a, b) => a.page_number - b.page_number);
      } catch (error) {
        // pass
      }
    },

    exportFile(response, filename) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    },

    async download() {
      try {
        this.downloading = true;
        const response = await http.get(`download/files/?pli_id=${this.$route.params.id}`, {
          responseType: "arraybuffer",
        });
        const fileName = this.document.name
        .replace(/,/g, "")
        .replace(/\.[^/.]+$/, ".zip");
        this.exportFile(response, fileName);
      } catch (error) {
        // pass
      } finally {
        this.downloading = false;
      }
    },

    addUpdatedPage(page) {
      const index = this.updatedPages.findIndex(p => p.page_id === page.page_id);
      if (index === -1) {
        this.updatedPages.push(page);
      } else {
        this.updatedPages[index] = page;
      }
    },

    async submitReview() {
      try {
        this.submittingReview = true;
        let data = null;
        if (this.document.is_bundle) {
          data = this.pages.map(page => ({
            page_id: page.id,
            category_id: page.category_id,
          }));
        } else {
          data = {
            document_id: this.document["id"],
            category_id: this.document.category_id,
          }
        }
        await ClassifyDocumentsAPI.updateDocument(this.document.id, this.document.is_bundle, data);
        this.$store.commit(
          'setSuccessMessage',
          this.$t('workflows.documents.review_submitted'),
        );
        this.$store.commit('setSuccessSnackbar', true);
        this.$router.push({ name: 'Corrections', query: { activeTab: 'documents' } });
      } catch (error) {
        // pass
      } finally {
        this.submittingReview = false;
      }
    },

    getDiscardReasons(){
      const reasons = [
        'file_corrupted',
        'unreadable_content',
        'other'
      ];

      return reasons.map(reason => ({
        title: this.$t(`workflows.documents.${reason}`),
        value: this.$t(`workflows.documents.${reason}`)
      }));
    },

    async submitDiscardReview(discardReason){
      let rejectionData = {discard_reason: discardReason};
      const success = await ClassifyDocumentsAPI.discard(this.document.id, rejectionData);
      if (success) {
          this.$store.commit(
          'setSuccessMessage',
          this.$t('workflows.documents.review_discarded'),
        );
        this.$store.commit('setSuccessSnackbar', true);
        this.$router.push({ name: 'Corrections', query: { activeTab: 'documents' } });
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.document-view {
  padding: 40px 40px 350px 60px !important;
  min-height: 100vh;

  &--open-doc-viewer.closed-router {
    width: calc(100vw - 810px);
  }

  &--open-doc-viewer.open-router {
    width: calc(100vw - 660px);
  }

  .sidebar {
    position: fixed;
    right: 0px;
    bottom: 0px;
    top: 0px;
    background-color: darkgray;
    transition: width 300ms;
    text-align: center;
    z-index: 1000;
  }

  .background-spread {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}
</style>
