<template>
  <div
    v-if="email !== null"
    class="page-padding py-7"
  >
    <BackLink
      v-if="datasetId !== null"
      :text="datasetName"
      @click="() => $router.push({ name: 'EmailsDatasetView', params: { datasetId } })"
      show-text
    />
    <Email
      :email="email"
    />
  </div>
</template>

<script>
import { EmailsAPI } from '@/API/classify/EmailsAPI';
import BackLink from '@/components/common/elements/Navigation/BackLink.vue';
import Email from '@/components/common/elements/Emails/Email.vue';


export default {
    name: 'EmailView',
    components: {
      BackLink,
      Email,
    },

    data() {
      return {
        email: null,
      };
    },

    async created() {
      await this.fetchEmail();
    },

    methods: {
      async fetchEmail() {
        this.email = await EmailsAPI.fetchEmail({ emailId: this.emailId });
      },
    },

    props: {
      emailId: {
        type: Number,
        required: true,
      },
      datasetName: {
        type: String,
        required: true,
      },
      datasetId: {
        type: Number,
        default: null,
      }
    },
}
</script>

<style lang="scss" scoped>

</style>