import { http } from '@/plugins/axios';

const BASE_PATH = 'classify/api/v1/emails_datasets';

export class EmailsDatasetsAPI {
  static fetchAll({
    name = null,
    offset = 0,
    limit = 20,
    statuses = null,
    descendingSort = true,
  }) {
    return http
      .get(`${BASE_PATH}`, {
        params: {
          name,
          offset,
          limit,
          statuses: statuses,
          descending_sort: descendingSort,
        },
        /* When passing a list as a query parameter, per default axios serialize
         * it this way param[]=val1&param[]=val2 but fastapi does not handle this form.
         * In order to serialize the list parameter in the form supported by
         * fastapi (param=val1&param=val2) we need to set the indexes attribute of the
         * paramsSerializer to null */
        paramsSerializer: {
          indexes: null,
        }
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static get(id) {
    return http
      .get(`${BASE_PATH}/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static create(dataset) {
    return http
      .post(`${BASE_PATH}`, {
        ...dataset,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static update({datasetId, description = null, emailsIds = null}) {
    return http
      .put(`${BASE_PATH}/${datasetId}/`, {
        description,
        emails_ids: emailsIds,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static uploadEmailFile(datasetId, files) {
    const formData = new FormData();
    files.forEach(file => { formData.append("files", file); });
    const headerConfig = { headers: { 'Content-Type': 'multipart/form-data' } }
    return http
      .post(`${BASE_PATH}/${datasetId}/emails-files`, formData, headerConfig)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static fetchEmails({datasetId, offset=0, limit=20, descendingSort=true, fileName=undefined}) {
    return http
      .get(`${BASE_PATH}/${datasetId}/emails-files`, {
        params: {
          offset,
          limit,
          descending_sort: descendingSort,
          file_name: fileName,
        }
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }
}
