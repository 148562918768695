<template>
  <TableWithFooter
    :loading="loading"
    :paginated-items-length="pages[currentPage]?.length ?? 0"
    :total="filteredItems.length"
    :current-page="currentPage"
    :items-per-page="itemsPerPage"
    @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
    @change-page="(page) => currentPage = page"
    include-footer-in-table-card
  >
    <template #header>
      <v-col cols="8">
        <SortButton
          v-model="sortDesc"
          class="mr-1"
          style="margin-top: -5px"
        />
        {{ $t('datatable.header.dataPointName') }}
      </v-col>
      <v-col class="justify-center">
        {{ $t('display') }}
      </v-col>
    </template>
    <template #body>
      <v-container
        class="pa-0"
        fluid
      >
        <v-row
          v-for="item in pages[currentPage]"
          :key="item.id"
          class="table-row fade-in table-row-height"
          style="border-bottom: 1px solid #eee !important"
        >
          <v-col cols="8">
            <ItemName
              :key="item.id"
              :ref="`name_${item.id}`"
              class="ml-6"
              style="z-index: 202; max-width: calc(100% - 24px);"
              :item="item"
              :clickable="false"
            />
          </v-col>
          <v-col
            v-if="user.role === 'orgadmin'"
            class="justify-center"
          >
            <v-switch
              v-model="item.display_in_review"
              color="primary"
              density="dense"
              :disabled="disabled"
              @change="updateIntermediate(item)"
              hide-details
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
  </TableWithFooter>
</template>

<script>
import ItemName from '@/components/common/elements/General/ItemName';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import SortButton from '@/components/common/elements/Tables/SortButton';
import { http } from '@/plugins/axios';


export default {
  name: 'DataPointsReviewTable',

  components: {
    ItemName,
    TableWithFooter,
    SortButton,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      itemsPerPage,
      currentPage,
    };
  },

  computed: {
    user() {
      return this.$store.getters.loggedInUser;
    },

    isAdmin() {
      return ['orgadmin', 'sysadmin'].includes(this.user.role);
    },

    sortDesc: {
      get() {
        return this.$store.getters.dpSortDesc;
      },
      set(sortDesc) {
        this.$store.commit('setDpSortDesc', sortDesc);
      },
    },

    selected: {
      get() {
        if (this.filteredItems.length > 0) {
          return this.filteredItems.filter(item => item.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },

    pages: {
      get() {
        const pages = {};
        let page = 0;
        if (this.itemsPerPage > 0) {
          this.filteredItems.forEach((item, i) => {
            if (!(i % this.itemsPerPage)) {
              page++;
              pages[page] = [];
            }
            pages[page].push(item);
          });
        } else {
          pages[1] = [...this.filteredItems];
        }
        return pages;
      },
      set() {
        // pass
      }
    },
  },

  watch: {
    sortDesc() {
      this.$emit('getItems', this.sortDesc);
    },

    itemsPerPage() {
      this.resetCurrentPage();
    },
  },

  methods: {
    resetCurrentPage() {
      this.currentPage = 1;
      const items = this.allItems.map(item => {
        item.selected = false;
        return item;
      });
      this.$emit('setItems', [...items]);
    },

    async updateIntermediate(dp) {
      // wait is required here to avoid the "glitching" of the switch
      const waitTime = 200;
      setTimeout(async () => {
        try {
          await http.put(`system_2/data_point/${dp.id}/`, { intermediate: !dp.display_in_review });
          this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.updated'));
          this.$store.commit('setSuccessSnackbar', true);
          this.$emit('saveName');
        } catch (error) {
          // pass
        }
      }, waitTime);
    },
  },

  props: {
    allItems: {
      type: Array,
      required: true,
    },

    filteredItems: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['setItems', 'getItems', 'saveName'],
}
</script>
