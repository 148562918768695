<template>
  <div class="item-name d-flex">
    <div
      v-if="!rename"
      style="width: 100%"
    >
      <div class="display-name-container d-flex">
        <div
          ref="displayName"
          class="ellipsis display-name"
          :class="{
            'primary--text': clickable,
            clickable,
          }"
          @mouseover="hovered = true"
          @mouseleave="hovered = false"
          @click="$emit('nameClick')"
        >
          {{ item[nameField] }}
        </div>
        <v-tooltip
          v-if="showHashtag"
          color="#423F4F"
          location="bottom"
        >
          <template #activator="{ props }">
            <v-icon
              class="hashtag-icon"
              size="11"
              v-bind="props"
            >
              fas fa-hashtag
            </v-icon>
          </template>
          <span style="color: white">
            id: {{ item.id }}
          </span>
        </v-tooltip>
        <v-tooltip
          v-if="showEditIcon && !rename"
          color="#423F4F"
          loaction="bottom"
        >
          <template #activator="{ props }">
            <v-icon
              class="clickable left-gap-sm edit-icon"
              color="primary"
              size="16"
              v-bind="props"
              @click="startRenaming"
            >
              fas fa-pen
            </v-icon>
          </template>
          <span style="color: white">
            {{ $t("models.edit_name") }}
          </span>
        </v-tooltip>
        <v-tooltip
          v-if="item.is_locked"
          color="#423F4F"
          location="bottom"
        >
          <template #activator="{ props }">
            <v-icon
              size="16"
              class="left-gap-sm"
              style="margin: -2px"
              v-bind="props"
            >
              fas fa-lock
            </v-icon>
          </template>
          <span style="color: white">
            {{ $t('docTypes.doctype_is_locked') }}
          </span>
        </v-tooltip>
        <v-badge
          v-if="badge !== ''"
          color="primary-lighten1"
          style="margin-top: -5px"
          :content="badge"
          inline
        />
      </div>
      <v-tooltip
        v-if="hovered && isNameTooLong()"
        location="bottom"
        :activator="$refs.displayName"
      >      
        <template #default>
          <small>{{ item[nameField] }}</small>
        </template>
      </v-tooltip>
    </div>
    <div
      v-else
      style="width: 100%"
    >
      <input
        ref="itemNameField"
        v-model="newName"
        type="text"
        class="item-name-field primary--text"
        @keydown.enter="saveFileName"
        @keydown.esc="rename = false"
      >
      <v-tooltip 
        :disabled="!validateButtonDisabled"
        right
      >
        <template #activator="{ props }">
          <span v-bind="props">
            <v-btn
              class="left-gap-sm"
              variant="text"
              color="primary"
              :disabled="validateButtonDisabled"
              @click="saveFileName"
            >
              <v-icon
                size="16"
              >
                fas fa-check
              </v-icon>
            </v-btn>
          </span>
        </template>
        <span>{{ validateButtonMessage }}</span>
      </v-tooltip>
      <v-btn
        class="left-gap-sm"
        style="color: rgb(var(--v-theme-primary)) !important"
        variant="text"
        @click="$emit('cancelNameEdit'); rename = false;"
      >
        <v-icon size="16">
          fas fa-times
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';

export default {
  name: 'ItemName',

  data() {
    return ({
      rename: false,
      newName: '',
      hovered: false,
    });
  },

  computed: {
    showHashtag() {
      return this.showId && this.item.id > 0;
    },

    extension() {
      if (this.item && this.item[this.nameField]) {
        const re = /(?:\.([^.]+))?$/;
        const ext = re.exec(this.item[this.nameField])[1];
        return ext ? ext : '?';
      }
      return '?';
    },

    isValid() {
      return this.validator(this.newName);
    },
  },

  watch: {
    editingAllowed() {
      if (this.rename && !this.editingAllowed) {
        this.rename = false;
      }
    },

    editing(on) {
      if (on) {
        this.startRenaming();
      } else {
        this.rename = false;
      }
    },
  },

  mounted() {
    if (this.item && this.item[this.nameField]) {
      this.newName = this.type === 'file' ? this.item[this.nameField].slice(0, (this.extension.length + 1) * -1) : this.item[this.nameField];
    }
  },

  beforeUnmount() { 
    window.removeEventListener('resize', this.onResize); 
  },

  methods: {
    isNameTooLong() {
      return this.$refs.displayName.scrollWidth > this.$refs.displayName.clientWidth;
    },

    startRenaming() {
      this.rename = true;
      this.$emit('startNameEdit');
      nextTick(() => {
        this.$refs.itemNameField.focus();
      });
    },

    saveFileName() {
      if (!this.isValid) {
        return;
      }
      const newName = this.type === 'file' ? `${this.newName}.${this.extension}` : this.newName;
      this.$emit('saveFileName', this.item.id, newName);
      this.rename = false;
    },
  },

  props: {
    item: {
      required: true,
      type: Object,
    },

    type: {
      type: String,
      required: false,
      default: 'default',
    },

    editingAllowed: {
      type: Boolean,
      default: false,
    },

    showEditIcon: {
      type: Boolean,
      default: false,
    },

    clickable: {
      type: Boolean,
      default: true,
    },

    showId: {
      type: Boolean,
      default: true,
    },

    editing: {
      type: Boolean,
      default: false,
    },

    nameField: {
      type: String,
      default: 'name',
    },

    validator: {
      type: Function,
      default: () => true,
    },

    hint: {
      type: String,
      default: '',
    },

    badge: {
      type: String,
      default: '',
    },
  },

  emits: ['nameClick', 'startNameEdit', 'cancelNameEdit', 'saveFileName'],
}
</script>

<style lang="scss" scoped>
.item-name {
  width: 100%;

  .display-name-container {
    position: relative;
  }

  .hashtag-icon {
    color: rgb(var(--v-theme-grey-darken2)) !important;
    margin-left: 3px;
    margin-top: 5px;
  }

  .hashtag-icon:hover {
    color: rgb(var(--v-theme-primary)) !important;
  }

  .item-name-field {
    display: inline-block;
    background-color: #ffffff00;
    padding: 0 !important;
    border: none;
    width: calc(100% - 70px);

    &:focus {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }

    &:focus-visible {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }
  }
}
</style>
