import { store } from '@/plugins/store';

export function displaySnackbarSuccess(message) {
  store.commit('setSuccessMessage', message);
  store.commit('setSuccessSnackbar', true);
}

export function displaySnackbarError(message) {
  store.commit('setErrorMessage', message);
  store.commit('setSnackbar', true);
}