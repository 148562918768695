<template>
  <div class="emails-dataset-editor page-padding py-7">
    <div class="emails-dataset-editor__actions top-gap">
      <v-text-field
        v-model="dataset.name"
        class="dataset-name-filter"
        variant="outlined"
        color="primary"
        density="compact"
        :rules="[validateDatasetName]"
        :placeholder="$t('models.type_new_dataset_name')"
        :error-messages="errorMessage"
        @keydown.enter="saveEmailDataset"
      />
      <div class="save-button-container">
        <v-tooltip right>
          <template #activator="{ props }">
            <v-icon
              class="right-gap-sm clickable left-gap-sm"
              color="primary"
              size="16"
              v-bind="props"
            >
              fas fa-info-circle
            </v-icon>
          </template>
          {{ $t('emailsDatasets.unique_name') }}
        </v-tooltip>
        <v-btn
          color="primary"
          :disabled="hasError"
          @click="saveEmailDataset"
          rounded
        >
          {{ $t('save') }}
        </v-btn>
      </div>
    </div>
    <FileUploader
      :disabled="hasError"
      :initials-files="files"
      :supported-extensions="$options.constants.SUPPORTED_EXTENSIONS"
      @files-selected="handleFilesSelect"
    />
  </div>
</template>
<script>

import { EmailsDatasetsAPI } from '@/API/classify/EmailsDatasetsAPI.js';
import FileUploader from '@/components/common/elements/Forms/FileUploader';
import { uploadEmails, SUPPORTED_EXTENSIONS } from './EmailUploadsHelper';
import { displaySnackbarSuccess } from '@/utils/snackbar.js';

export default {
  name: 'EmailsDatasetEditor',

  components: {
    FileUploader,
  },

  constants: {
    DATASET_NAME_MIN_LENGTH: 5,
    DATASET_NAME_MAX_LENGTH: 64,
    SUPPORTED_EXTENSIONS
  },

  data() {
    return {
      hasError: false,
      errorMessage: '',
      dataset: {
        name: '',
      },
      files: [],
    };
  },

  watch: {
    'dataset.name': function() {
      this.validateDatasetName();
    },
  },

  methods: {
    async saveEmailDataset() {
      if (this.hasError) {
        return;
      }
      if (!this.dataset.id) {
        try {
          const response = await EmailsDatasetsAPI.create(this.dataset);
          this.dataset.id = response.data;
          displaySnackbarSuccess(this.$t('emailsDatasets.save_dataset_success'));
        } catch (error) {
          return
        }
      }

      if (this.files) {
        this.uploadEmails();
      }
    },

    async fetchDataset() {
      const response = await EmailsDatasetsAPI.get(this.dataset.id);
      this.dataset = response.data;
    },

    async handleFilesSelect(files) {
      this.files = files;
      await this.saveEmailDataset();
    },

    async uploadEmails() {
      try {
        await uploadEmails(this.dataset.id, this.files);
        this.$router.push({ name: 'EmailsDatasetView', params: { datasetId: this.dataset.id } });
      } catch (error) {
        this.files = [];
      }
    },

    validateDatasetName() {
      const constants = this.$options.constants;
      if (this.dataset.name.length < constants.DATASET_NAME_MIN_LENGTH) {
        this.hasError = true;
        this.errorMessage = this.$t(
          'emailsDatasets.short_dataset_name_error', {
          nb_char: constants.DATASET_NAME_MIN_LENGTH
        });
        return;
      }
      if(this.dataset.name.length > constants.DATASET_NAME_MAX_LENGTH) {
        this.hasError = true;
        this.errorMessage = this.$t(
          'emailsDatasets.long_dataset_name_error', {
          nb_char: constants.DATASET_NAME_MAX_LENGTH
        });
        return
      }
      this.errorMessage = '';
      this.hasError = false;
    },
  },
};
</script>

<style lang="scss" scoped>

::v-deep .emails-dataset-editor__actions {
  display: flex;
  align-items: start;

  .dataset-name-filter {
    max-width: 300px;
  }

  .save-button-container {
    display: flex;
    align-items: center;
    height: 2.625rem;
  }

  .v-input__details {
    padding-inline: 0px;
  }

}

</style>