<template>
  <div class="validator d-flex">
    <div class="half-left">
      <BackLink
        :text="$t('docTypes.all_documents')"
        @back-click="$emit('backClick')"
      />
      <div v-if="numberOfFiles > 0 && numberOfExtractors > 0">
        <DocNavigator
          v-model="selectedId"
          :docs="files"
          :total-docs="totalFilesDisplay"
          :ordinal-selected="currentIndex + 1"
          :loading="filesLoading"
          :show-loading-skeleton="unknownPosition || currentIndex < 0"
          @delete-click="handleDeleteButton"
          @get-more="getFiles(numberOfFiles)"
          @get-missing="getMissingFiles"
          @step="(step) => currentIndex += step"
          can-copy-filename
          deleteable
        />
        <v-card
          v-if="extractionInProgress"
          class="d-flex justify-center align-center flex-column top-gap"
          height="300"
        >
          <v-icon
            class="mb-4"
            color="primary"
            size="30"
          >
            fas fa-spinner fa-pulse
          </v-icon>
          <div class="text-h3 gray--text">
            {{ $t("verification.extraction_in_progress") }}
          </div>
        </v-card>
        <v-card
          v-else-if="fileError"
          class="d-flex justify-center align-center flex-column top-gap"
          height="300"
        >
          <div class="text-h3 gray--text">
            {{ $t("verification.extraction_error") }}
          </div>
        </v-card>
        <div
          v-else
          :key="`fileValues-${selectedId}`"
          class="mt-5"
        >
          <v-card
            v-if="$store.getters.loadingScreen || noValues"
            class="d-flex justify-center align-center flex-column"
            height="300"
          >
            <v-icon
              class="mb-4"
              color="primary"
              size="30"
            >
              fas fa-spinner fa-pulse
            </v-icon>
            <div class="text-h3 gray--text">
              {{ $t("verification.loadingValues") }}
            </div>
          </v-card>
          <div v-else>
            <div
              v-if="$store.getters.totalRulesDisplay"
              style="text-align: right"
            >
              <v-btn
                color="primary"
                class="small-button"
                @click="autoValidate"
                rounded
              >
                <v-icon
                  size="16"
                  start
                >
                  fas fa-magic
                </v-icon>
                {{ $t('docTypes.auto_validate') }}
              </v-btn>
            </div>
            <div v-if="objectValues.length > 0">
              <v-card
                class="dp-card pa-0 mt-2"
                tile
              >
                <ObjectValidator
                  :object-values="objectValues"
                  :highlighted-object="highlightedObject"
                  :current-file="currentFile"
                  @handle-add-object="handleAddObject"
                  @cancel-add-object="cancelAddObject"
                  @handle-object-enter="handleObjectEnter"
                  @handle-object-leave="handleObjectLeave"
                  @get-object-values="getObjectValues"
                />
              </v-card>
            </div>
            <div v-if="values.length > 0">
              <div class="label top-gap">
                {{ $t("docTypes.data_points") }}
              </div>
              <v-card
                class="dp-card pa-0 mt-2"
                tile
              >
                <draggable
                  v-model="values"
                  ghost-class="ghost-dp"
                  @start="drag = true"
                  @end="drag = false"
                  @change="(event) => handleDrag(event, values)"
                >
                  <DPValidator
                    v-for="dataPoint in values"
                    :id="`dpValidator-${dataPoint.data_point_id}`"
                    :key="getDPValueKey(dataPoint)"
                    :ref="`dpValidator-${dataPoint.data_point_id}`"
                    :data-point="dataPoint"
                    :editing="editing"
                    :active-datapoint="activeDP"
                    :business-rule-strings="dataPointBRStrings[dataPoint.data_point_id] || []"
                    @verify-value="(value) => verifyValue(value, selectedId)"
                    @cancel-d-p-verification="(dataPointId) => cancelDPVerification(dataPointId, selectedId)"
                    @hover="handleHover"
                    @de-highlight="deHighlight"
                    @edit-value="handleEditValue"
                    @cancel-edit="cancelEdit"
                  />
                </draggable>
              </v-card>
            </div>
            <div v-if="extractedGroups.length > 0 || emptyGroups.length > 0">
              <div class="label top-gap bottom-gap-sm">
                {{ $t("docTypes.label_groups") }}
              </div>
              <div v-if="extractedGroups.length > 0">
                <div
                  v-for="group in extractedGroups"
                  :key="group.group_id"
                >
                  <GroupHeader
                    :group="group"
                    :loading="false"
                    :adding-group="addingGroup"
                    :show-verify="false"
                    @add-subgroup="addSubgroup"
                  />
                  <v-card
                    class="dp-card pa-0"
                    tile
                  >
                    <v-expansion-panels
                      v-model="group.panel"
                      multiple
                      focusable
                    >
                      <GroupValidator
                        v-for="(subgroup, i) in group.subgroups"
                        :id="`groupValidator-${selectedId}-${group.group_id}-${i}`"
                        :key="i"
                        :ref="`groupValidator-${selectedId}-${group.group_id}-${i}`"
                        :name="group.group_name"
                        :index="i + 1"
                        :group-id="group.group_id"
                        :subgroup-id="subgroup.id"
                        :origin="subgroup.origin"
                        :values="subgroup.values"
                        :active-value="activeValue"
                        :editing="editing"
                        :label-map="getLabelMap(group.group_id)"
                        :adding-label="addingLabel"
                        :new-subgroup-values="newSubgroupValues"
                        :editing-label="editingLabel"
                        :file-id="selectedId"
                        :verify-group="verifyGroup"
                        :cancel-group="cancelGroup"
                        :business-rule-strings="groupValueBRStrings"
                        @highlight="groupHighlight"
                        @de-highlight="deHighlight"
                        @verify-value="verifyGroupValue"
                        @cancel-group-value="cancelGroupValue"
                        @edit-value="editGroupValue"
                        @cancel-edit="cancelEdit"
                        @add-missing-label="addMissingLabel"
                        @add-subgroup-label="
                          (label) => addSubgroupLabel(group, label)
                        "
                        @edit-subgroup-label="
                          (label) => (editingLabel = label)
                        "
                        @save-label="saveLabel(subgroup)"
                        @delete-subgroup-label="deleteSubgroupLabel"
                        @cancel-add-label="cancelAddLabel"
                        @delete-subgroup="(subgroupId) => deleteSubgroup(subgroupId, group.group_index)"
                      />
                    </v-expansion-panels>
                    <NewSubgroup
                      v-if="addingGroup === group.group_id"
                      class="mb-5"
                      :group="group"
                      :index="newSubgroupIndex"
                      :group-index="group.group_index"
                      :label-map="getLabelMap(group.group_id)"
                      :editing-label="editingLabel"
                      :values="newSubgroupValues"
                      @add-subgroup-label="addSubgroupLabel"
                      @delete-subgroup-label="deleteSubgroupLabel"
                      @edit-subgroup-label="(label) => (editingLabel = label)"
                      @save-subgroup="saveSubgroup"
                      @cancel-add-subgroup="cancelAddSubgroup"
                    />
                  </v-card>
                  <GroupHeader
                    :group="group"
                    :loading="false"
                    :adding-group="addingGroup"
                    :show-verify="false"
                    @add-subgroup="addSubgroup"
                    as-footer
                  />
                </div>
              </div>
              <div v-if="emptyGroups.length > 0">
                <div
                  v-for="group in emptyGroups"
                  :key="group.group_id"
                >
                  <GroupHeader
                    :group="group"
                    :loading="false"
                    :adding-group="addingGroup"
                    :show-verify="false"
                    @add-subgroup="addSubgroup"
                  />
                  <v-card
                    class="dp-card pa-0"
                    tile
                  >
                    <EmptyGroupValidator :group="group" />
                    <NewSubgroup
                      v-if="addingGroup === group.group_id"
                      class="mb-5"
                      :group="group"
                      :index="newSubgroupIndex"
                      :group-index="group.group_index"
                      :label-map="getLabelMap(group.group_id)"
                      :editing-label="editingLabel"
                      :values="newSubgroupValues"
                      @add-subgroup-label="addSubgroupLabel"
                      @delete-subgroup-label="deleteSubgroupLabel"
                      @edit-subgroup-label="(label) => (editingLabel = label)"
                      @save-subgroup="saveSubgroup"
                      @cancel-add-subgroup="cancelAddSubgroup"
                    />
                  </v-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-card
        v-else-if="loaded"
        class="d-flex justify-center align-center flex-column"
        height="300"
      >
        <div class="text-center">
          <div
            v-if="numberOfFiles === 0"
            class="d-flex flex-column align-center"
          >
            {{ $t("verification.no_verification_file") }}
            <v-btn
              class="mt-4"
              color="primary"
              @click="$emit('addFile')"
              rounded
            >
              <v-icon
                size="16"
                start
              >
                fas fa-plus
              </v-icon>
              {{ $t("datatable.header.addDocs") }}
            </v-btn>
          </div>
          <div
            v-else-if="numberOfExtractors === 0"
            class="d-flex flex-column align-center"
          >
            <div style="padding-bottom: 20px">
              {{ $t("verification.no_verification_dp") }}
            </div>
            <AddExtractorButton class="top-gap" />
          </div>
        </div>
      </v-card>
    </div>
    <div class="half-right">
      <DocViewer
        v-if="$store.getters.loggedInUser.role === 'orgadmin'"
        ref="filesDocViewer"
        type="validator"
        style="width: 100%"
        :document-type="documentType"
        :document="currentFile"
        :loading="!loaded && selectedId < 0 && numberOfFiles > 0"
        :viewer-values="viewerValues"
        :active-datapoint="highlightedValue"
        :highlighted-object="highlightedObject"
        :text-select="editing !== -1 || editingLabel !== '' || addingObject != -1"
        :has-files="numberOfFiles > 0"
        :raw-values="rawValues"
        :object-values="objectValues"
        :raw-group-values="rawGroupValues"
        @change-page="forceCompute += 5"
        @highlight="handleDocViewerHighlight"
        @handle-object-enter="handleObjectEnter"
        @handle-object-leave="handleObjectLeave"
        @de-highlight="deHighlight"
        @update-coordinates="updateCoordinates"
        @verify="verifyFromViewer"
        @zooming="handleZoom"
        searchable
      />
      <DeleteDialog
        v-model="deleteDialog"
        :title="$t('datatable.deleteFile')"
        :message="$t('datatable.deleteConfirmation')"
        @confirm="deleteFile"
        @close="deleteDialog = false"
      />
    </div>
  </div>
</template>
<script>
import { VueDraggableNext } from "vue-draggable-next";

import AddExtractorButton from '@/components/extract/elements/Extractors/AddExtractorButton';
import DocNavigator from "@/components/extract/elements/Documents/DocNavigator";
import DPValidator from '@/components/extract/elements/Validation/DPValidator';
import ObjectValidator from '@/components/extract/elements/Validation/ObjectValidator';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import GroupValidator from "@/components/extract/elements/Validation/GroupValidator";
import EmptyGroupValidator from "@/components/extract/elements/Validation/EmptyGroupValidator";
import NewSubgroup from "@/components/extract/elements/Validation/NewSubgroup";
import DocViewer from "@/components/extract/elements/DocViewer/DocViewer.vue";
import GroupHeader from "@/components/extract/elements/Validation/GroupHeader";
import BackLink from '@/components/common/elements/Navigation/BackLink.vue';

import file_mixin from "@/mixins/file.js";
import model_mixin from "@/mixins/model.js";
import validation_mixin from "@/mixins/validation";

import { http } from "@/plugins/axios";
import { BusinessRuleAPI } from "@/API/extract/BusinessRuleAPI";
import { getDataPointBRStrings, getGroupValueBRStrings } from "@/utils/BusinessRuleUtils";

export default {
  name: "ValidatorView",

  mixins: [
    file_mixin,
    model_mixin,
    validation_mixin,
  ],

  components: {
    DocNavigator,
    DPValidator,
    DeleteDialog,
    GroupValidator,
    EmptyGroupValidator,
    NewSubgroup,
    DocViewer,
    ObjectValidator,
    AddExtractorButton,
    GroupHeader,
    BackLink,
    draggable: VueDraggableNext,
  },

  data() {
    return {
      currentIndex: -1,
      forceCompute: 10,
      groupValues: [],
      infoCategories: [
        "hasText",
        "hasEntities",
        "hasTables",
        "hasLayout",
        "hasForms",
      ],
      invalidStatus: ["uploaded", "ingested", "error"],
      rawGroupValues: [],
      validatorKey: 10,
      values: [],
      addingObject: -1,
      highlightedObject: {},
      objectValues: [],
      loaded: false,
      controlPressed: false,
      deleteDialog: false,
      singleFile: null,
      missingCheck: null,
      totalRules: 0,
      businessRules: [],
      ruleResults: {},
      dataPointBRStrings: {},
      groupValueBRStrings: {},
    };
  },

  computed: {
    numberOfExtractors() {
      return this.dataPoints.length + this.labelGroups.length;
    },

    extractionInProgress() {
      return this.selectedId > 0 && this.currentFile.status && ['uploaded', 'ingested'].includes(this.currentFile.status);
    },

    fileError() {
      return this.selectedId > 0 && this.currentFile.status && this.currentFile.status === 'error';
    },

    noValues() {
      return (
        this.values.length === 0 &&
        this.groupValues.length === 0 &&
        this.emptyGroups.length === 0
      );
    },

    dataPoints: {
      cache: false,
      get: function () {
        if (this.$store.getters.dataPoints[this.$route.params.id]) {
          let dataPoints =
            this.$store.getters.dataPoints[this.$route.params.id];
          dataPoints = dataPoints.map((dp) => {
            dp.category =
              dp.extraction_paths.length > 0 &&
              dp.extraction_paths[0].what.custom_entity
                ? "custom"
                : "classic";
            return dp;
          });
          return dataPoints;
        }
        return [];
      },
      set: function (newDataPoints) {
        this.$store.commit("setDataPoints", {
          typeId: this.$route.params.id,
          dataPoints: newDataPoints,
        });
      },
    },

    labelGroups: {
      cache: false,
      get: function () {
        if (this.$store.getters.labelGroups[this.$route.params.id]) {
          return this.$store.getters.labelGroups[this.$route.params.id];
        }
        return [];
      },
      set: function (newGroups) {
        this.$store.commit("setLabelGroups", {
          typeId: this.$route.params.id,
          labelGroups: newGroups,
        });
      },
    },

    rawValues() {
      return this.values || [];
    },

    extractedGroups() {
      if (
        this.selectedId > 0 &&
        this.groupValues.length > 0
      ) {
        return this.groupValues.filter(
          (g) => g.subgroups.length > 0
        );
      }
      return [];
    },

    emptyGroups() {
      if (
        this.selectedId > 0 &&
        this.groupValues.length > 0
      ) {
        return this.groupValues.filter(
          (g) => g.subgroups.length === 0
        );
      }
      return [];
    },

    selectedId: {
      get() {
        return this.$store.getters.validatorFile;
      },
      set(id) {
        this.$store.commit("setValidatorFile", id);
      },
    },

    currentFile() {
      if (this.unknownPosition) {
        return this.singleFile;
      }
      if (this.selectedId >= 0) {
        const file = this.files.find((file) => file.id === this.selectedId);
        if (file) {
          return file;
        }
      }
      return { id: -1, pages: [] };
    },
    zoom: {
      get() {
        return this.$store.getters.validatorZoom;
      },
      set(zoom) {
        this.$store.commit("setValidatorZoom", zoom);
      },
    },

    currentPageData() {
      if (this.currentFile.pages.length > 0) {
        return this.currentFile.pages[this.currentPage];
      }
      return {};
    },
  },

  watch: {
    async extractionInProgress(extracting) {
      if (
        !extracting &&
        this.currentFile &&
        this.currentFile.status &&
        !this.invalidStatus.includes(this.currentFile.status)
      ) {
        await this.getAllValues();
        await this.getAllRuleInfo();
      }
    },

    async selectedId(newFile, old) {
      this.handleSelectChange(newFile);
      if (
        newFile !== old &&
        newFile > 0 &&
        this.currentFile.status &&
        !this.invalidStatus.includes(this.currentFile.status)
      ) {
        this.cancelAddSubgroup();
        this.cancelAddLabel();
        this.$store.commit("setLoadingScreen", true);
        await this.getAllValues();
        await this.getAllRuleInfo();
        this.$store.commit("setLoadingScreen", false);
      }
    },

    editing() {
      if (this.editing != -1 && this.addingObject != -1){
        const dpObject = this.objectValues
          .filter(item => item['object_dp_id'] === this.addingObject)[0];

        dpObject["object_values"] = dpObject["object_values"].filter(obj => !('adding' in obj));
        this.addingObject = -1;
      }
    },

    editingLabel() {
      if (this.editingLabel !== '' && this.addingObject != -1){
        const dpObject = this.objectValues
          .filter(item => item['object_dp_id'] === this.addingObject)[0];

        dpObject["object_values"] = dpObject["object_values"].filter(obj => !('adding' in obj));
        this.addingObject = -1;
      }
    }
  },

  async mounted() {
    this.files = [];
    await this.getDataPoints(false);
    await this.getLabelGroups(false);
    this.$emit('gotExtractors');
    await this.getFiles();
    let index = this.validating.index;
    if (this.validating.id === -1) {
      index = 0;
    }
    if (index === -1) {
      index = this.files.findIndex(f => f.id === this.validating.id);
    }
    if (this.numberOfFiles > 0) {
      if (index === -1) {
        await this.getFile();
        this.unknownPosition = true;
        this.selectedId = this.validating.id;
        this.findMissingPosition();
      } else {
        this.currentIndex = index;
      }
      setTimeout(async () => {
        if (
          this.labelGroups.length > 0 &&
          this.numberOfFiles > 0 &&
          this.currentFile.status &&
          !this.invalidStatus.includes(this.currentFile.status) &&
          this.values.length === 0 &&
          this.groupValues.length === 0
        ) {
            await this.getAllValues();
        }
        this.loaded = true;
      }, 50);
      this.handleKeys();
    } else {
      this.loaded = true;
    }
  },

  unmounted() {
    clearTimeout(this.missingCheck);
    this.stopKeysListener();
    this.currentIndex = -1;
    this.selectedId = -1;
  },

  methods: {
    async getBusinessRules(offset = 0, limit = 100) {
      if (offset === 0) {
        this.businessRules = [];
      }
      const [rules, total] = await BusinessRuleAPI.get(offset, limit, this.$route.params.id);
      this.businessRules = [...this.businessRules, ...rules];
      if (this.businessRules.length < total) {
        await this.getBusinessRules(offset + limit, limit);
      }
    },
    
    async getAllRuleInfo() {
      try {
        await this.getBusinessRules();
        this.ruleResults = await BusinessRuleAPI.getRuleResults(this.selectedId);
        this.dataPointBRStrings = getDataPointBRStrings(
          this.businessRules,
          this.values,
          this.ruleResults,
        );
        this.groupValueBRStrings = getGroupValueBRStrings(
          this.businessRules,
          this.groupValues,
          this.ruleResults,
        );
      } catch (error) {
        // pass
      }
    },

    async autoValidate() {
      this.$store.commit('setLoadingScreen', true);
      try {
        await http.post(`system_2/verify/auto/${this.selectedId}/`, {});
        await this.getAllValues();
        await this.getAllRuleInfo();
      } catch (error) {
        // pass
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    async getFile() {
      this.$store.commit('setLoadingScreen', true);
      try {
        const response = await http.get(`files/${this.validating.id}/`);
        this.singleFile = response.data;
      } catch (error) {
        // pass
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    handleDrag(event, values) {
      this.saveDPPos(
        event.moved.element.data_point_id,
        event.moved.newIndex,
        values
      );
    },

    async saveDPPos(id, pos, values) {
      const positions = values.map((dp) => dp.position);
      if (new Set(positions).size !== positions.length) {
        for (let i = 0; i < values.length; i++) {
          await this.updatePos(values[i].data_point_id, i);
        }
      }
      await this.updatePos(id, pos);
    },

    async updatePos(id, pos) {
      await http.put(`system_2/data_point/${id}`, { position: pos });
    },

    async getIndex(attribute, value, index = -1) {
      if (index < 0 && this.numberOfFiles <= this.totalFilesDisplay) {
        index = this.files.findIndex(f => f[attribute] === value);
        let offset = this.numberOfFiles;
        await this.getFiles(offset);
        return await this.getIndex(attribute, value, index);
      } else {
        return index;
      }
    },

    async getAllValues() {
      await Promise.all([
        this.getFileValues(),
        this.getGroupValues(),
        this.getObjectValues(),
      ]);
    },

    async getFileValues() {
      try {
        const response = await http.get(
          `files/${this.selectedId}/values/`
        );
        const values = [
          ...response.data.filter(dp => dp.value).sort(
            (a, b) => a.position - b.position
          )
        ];
        this.values = values;
      } catch (error) {
        // pass
      }
    },

    async getObjectValues() {
      try {
        const response = await http.get(
          `files/${this.selectedId}/objects/`
        );
        let dbObjectValues = response.data;
        dbObjectValues.forEach((objectDpInfo, index) => {
          objectDpInfo['object_values'].forEach(ov => {
              ov.panelIndex = index;
          });
        });
        this.objectValues = dbObjectValues;
      } catch (error) {
        // pass
      }
    },

    async getGroupValues() {
      try {
        const { data } = await http.get(`files/${this.selectedId}/groups/`);
        data.sort((a, b) => a.group_id - b.group_id);
        const rawValues = [...data];
        const groupValues = data.map((g, index) => {
          g.group_index = index;
          g.subgroups.sort((a, b) => a.id - b.id);
          for (let i = 0; i < g.subgroups.length; i++) {
            for (let j = 0; j < g.subgroups[i].values.length; j++) {
              g.subgroups[i].values[j].group_index = index;
              g.subgroups[i].values[j].subgroup_index = i;
              g.subgroups[i].values[j].index = j;
              g.subgroups[i].values[j].group_id = g.group_id;
            }
          }
          this.updatePanelIndexes(g, index);
          return g;
        });
        this.groupValues = [...groupValues];
        this.rawGroupValues = [...rawValues];
      } catch (error) {
        console.log(error);
      }
    },

    saveFilePageValues(index, page, dpId) {
      if (!this.files[index].pages[page.page_nb]) {
        this.files[index].pages[page.page_nb] = {};
      }
      if (!this.files[index].pages[page.page_nb].values) {
        this.files[index].pages[page.page_nb].values = {};
      }
      this.files[index].pages[page.page_nb].values[dpId] = page.values;
    },

    handleZoom(zoom) {
      this.zoom = zoom;
    },
    stopKeysListener() {
      window.removeEventListener("keydown", this.handleKeydown);
      window.removeEventListener("keyup", this.handleKeyup);
    },
    handleKeys() {
      window.addEventListener("keydown", this.handleKeydown);
      window.addEventListener("keyup", this.handleKeyup);
    },
    handleKeyup(event) {
      switch(event.code){
        case "ControlLeft":
          this.handleValidationControlUp(event);
          break;
        case "ControlRight":
          this.handleValidationControlUp(event);
          break;
      }
    },
    handleKeydown(event) {
      switch(event.code){
        case "Enter":
          this.handleValidationEnter(event);
          break;
        case "ControlLeft":
          this.handleValidationControl(event);
          break;
        case "ControlRight":
          this.handleValidationControl(event);
          break;
        default:
          this.controlPressed = false;
          break;
      }
    },
    handleValidationControlUp(event){
      event.preventDefault();
      this.controlPressed = false;
    },
    handleValidationControl(event) {
      event.preventDefault();
      this.controlPressed = true;
    },
    handleValidationEnter(event) {
      if (this.controlPressed){
        event.preventDefault();
        if (this.currentIndex == this.totalFiles - 1){
          this.currentIndex = 0;
        } else {
          this.currentIndex += 1;
        }
      }
    },

    handleObjectEnter(object){
      this.highlightedObject = object;
      this.currentPage = object.page_nb;
    },

    handleObjectLeave(){
      this.highlightedObject = {};
    },

    handleDeleteButton() {
      this.deleteDialog = true;
    },

    handleAddObject(object_dp_id) {
      if (this.addingObject == -1){
        const dpObjectValues = this.objectValues
          .filter(item => item['object_dp_id'] === object_dp_id) 
          .map(item => item['object_values'])[0]; 

        dpObjectValues.push({"adding": true, "page_nb": this.currentPage});
        this.addingObject = object_dp_id;
      }
    },

    cancelAddObject(object_dp_id) {
      const dpObject = this.objectValues
          .filter(item => item['object_dp_id'] === object_dp_id)[0];

      dpObject["object_values"] = dpObject["object_values"].filter(obj => !('adding' in obj));
      this.addingObject = -1;
    },

    async deleteFile(){
      if (this.selectedId > 0){
        await http.delete(`files/${this.selectedId}`);
      }
      this.finishDeletion();
    },

    async finishDeletion(){
      let index = this.files.findIndex(f => f.id === this.selectedId);
      this.files.splice(index, 1);
      this.files = [...this.files];
      this.totalFiles = this.totalFiles - 1;
      const newIndex = Math.min(index, Math.max(this.totalFiles - 1, 0));
      this.deleteDialog = false;
      if (this.totalFiles > 0 && index >= 0){
        await this.handleCurrentIndex(newIndex);
      }
      this.totalFilesDisplay = this.totalFiles;
      this.currentIndex = newIndex;
      await this.$store.commit(
        "setSuccessMessage", this.$t("docTypes.files.deleted")
      );
      this.$store.commit("setSuccessSnackbar", true);
    },
  },

  props: {
    documentType: {
      type: Object,
      required: true,
    },

    validating: {
      type: Object,
      default: () => {
        return {
          id: -1,
          index: -1,
        }
      }
    },
  },

  emits: ['backClick', 'addFile', 'gotExtractors'],
};
</script>

<style lang="scss" scoped>
.validator {
  padding: 0;
  height: fit-content;
}
.half-left {
  width: 40%;
  padding: 20px 24px;
  height: calc(100vh - 51px);
  overflow: scroll;
  overflow-x: hidden;
}
.half-right {
  width: 60%;
  height: calc(100vh - 60px);
}

.dp-card {
  border: 1px solid #aaa;
  border-radius: 0;
}

.ghost-dp {
  background-color: rgb(var(--v-theme-primary-lighten2));
}
</style>
